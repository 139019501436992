<template>


  <!-- index.html   13:53:02 GMT -->

  <head>

    <meta charset="utf-8" />
    <title>Desk Birdy | Parking Quick Stats</title>
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <meta content="Rental management system by Nouveta LTD" name="description" />
    <meta content="Kelvin Njuguna" name="author" />
    <!-- App favicon -->
    <link rel="shortcut icon" href="assets/images/favicon.svg">
    <!-- droping file -->
    <link href="assets/libs/dropzone/min/dropzone.min.css" rel="stylesheet" type="text/css" />

    <!-- DataTables -->
    <link href="assets/libs/datatables.net-bs4/css/dataTables.bootstrap4.min.css" rel="stylesheet" type="text/css" />
    <link href="assets/libs/datatables.net-buttons-bs4/css/buttons.bootstrap4.min.css" rel="stylesheet"
          type="text/css" />

    <!-- // Add the new slick-theme.css if you want the default styling -->
    <link rel="stylesheet" href="assets/libs/slick/slick.css"/>
    <link rel="stylesheet" href="assets/libs/slick/slick-theme.css"/>

    <!-- Bootstrap Css -->
    <link href="assets/css/bootstrap.min.css" id="bootstrap-style" rel="stylesheet" type="text/css" />
    <!-- Bootstrap select styling -->
    <link rel="stylesheet"
          href="https://cdn.jsdelivr.net/npm/bootstrap-select@1.13.14/dist/css/bootstrap-select.min.css">
    <!-- Icons Css -->
    <link href="assets/css/icons.min.css" rel="stylesheet" type="text/css" />
    <!-- App Css-->

    <!-- owl.carousel css -->
    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.carousel.min.css">

    <link rel="stylesheet" href="assets/libs/owl.carousel/assets/owl.theme.default.min.css">

    <link href="assets/css/app.min.css" id="app-style" rel="stylesheet" type="text/css" />
    <!-- custom Css-->
    <link href="assets/css/custom.css" id="app-style" rel="stylesheet" type="text/css" />

  </head>

  <body @contextmenu.prevent data-sidebar="dark">



  <!-- Begin page -->
  <div id="layout-wrapper">


    <NavigationBar/>


    <!-- ============================================================== -->
    <!-- Start right Content here -->
    <!-- ============================================================== -->
    <div  id="content" class="content-minimize">

      <div class="page-content">
        <div class="container-fluid">

          <!-- start page title -->
          <div class="row ">
            <div class="col-12">
              <div class="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 class="mb-sm-0 font-size-18">Plot Rent Register</h4>

                <div class="page-title-right">
                  <ol class="breadcrumb m-0">
                    <li class="breadcrumb-item"><a href="javascript: void(0);">Dashboards</a></li>
                    <li class="breadcrumb-item"><a href="javascript: void(0);">Land & Plots</a></li>
                    <li class="breadcrumb-item"><a href="javascript: void(0);">Plot Rent Register</a></li>
                  </ol>
                </div>

              </div>
            </div>
          </div>
          <!-- end page title -->

          <!-- tool bar -->

          <ToolBarPlot/>

          <!-- end of toolbar -->
          <div class="row">

            <div class="col-12 px-sm-30px">
              <div class="card">
                <div class="card-header bg-white  w-100 border-bottom d-flex justify-content-between align-items-center">
                  <h4 class="card-title text-capitalize">
                    Plot Rent Register
                  </h4>
                  <div class="d-flex">

                    <button data-bs-toggle="modal" data-bs-target=".add-plot-modal" type="button" class="btn btn-success text-uppercase dropdown-toggle option-selector me-3">
                      <i class="bx bx-plus font-size-16"></i> <span class="pl-1 d-md-inline">Add A Plot</span>
                    </button>

                    <button data-bs-toggle="modal" data-bs-target=".upload-plot-modal" type="button" class="btn btn-info text-uppercase dropdown-toggle option-selector me-3">
                      <i class="mdi mdi-file-document-outline font-size-16"></i> <span class="pl-1 d-md-inline">Upload Plots</span>
                    </button>

                  </div>

                </div>
                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                  <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">
                    <div class="flex-grow-1">
                      <div class="search-box mb-0 me-3">
                        <div class="position-relative">
                          <input v-model="keyword" type="text" class="form-control bg-light border-light rounded" placeholder="Search..." fdprocessedid="husj3l" spellcheck="false" data-ms-editor="true">
                          <i class="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

                <div class="card-header bg-white pt-0 pr-0 p-0 d-flex justify-content-between align-items-center w-100 border-bottom">

                  <div class="btn-toolbar p-3 d-flex justify-content-between align-items-center w-100" role="toolbar">


                    <div class="d-flex">
                      <select class="form-select w-auto font-13px mx-3" v-model="subCounty">
                        <option value="10">All Sub Counties</option>
                        <option :key="index" :value="item" v-for="(item, index) in subCounties">
                          {{ item.subCountyName }}
                        </option>
                      </select>

                      <select class="form-select w-auto font-13px mx-3" v-model="ward">
                        <option value="10">All Wards</option>
                        <option :key="index" :value="item"
                                v-for="(item, index) in wards">
                          {{ item.wardName }}
                        </option>
                      </select>

                      <select class="form-select w-auto font-13px mx-3">
                        <option value="10">All Attendants</option>
                      </select>

                      <select class="form-select w-auto font-13px mx-3">
                        <option value="10">All Plots</option>
                        <option value="25">Compliant</option>
                        <option value="50">Non Compliant</option>

                      </select>

                      <div class="d-flex align-items-center me-3 pe-3">
                        <span class="pr-3 fw-semibold text-nowrap">From (Registration Date):</span>
                        <input v-model="dateFrom" class="form-control" placeholder="Start date" type="date">
                        <span class="px-3 fw-semibold">TO:</span>
                        <input v-model="dateTo" class="form-control" placeholder="Start date" type="date">
                      </div>
                      <button data-bs-toggle="modal" data-bs-target=".download-modal" type="button" class="btn btn-primary dropdown-toggle option-selector me-3 text-capitalize">
                        <i class="bx bxs-download  font-size-16"></i> <span class="pl-1 d-md-inline">Download Report</span>
                      </button>

                    </div>


                  </div>
                  <div class="btn-toolbar p-3 align-items-center d-none animated delete-tool-bar" role="toolbar">
                    <button type="button" data-bs-toggle="modal" data-bs-target=".download-modal" class="btn btn-primary waves-effect btn-label waves-light me-3"><i class="mdi mdi-printer label-icon"></i> Print Selected Invoices</button>
                  </div>
                </div>
                <div class="card-body">
                  <div class="table-responsive">
                    <table class="table align-middle table-striped table-nowrap table-hover dt-responsive contacts-table" id="">
                      <thead class="table-dark">
                      <tr>
                        <th scope="col">Plot NO.</th>
                        <th>Plot Size</th>
                        <th>Added By</th>
                        <th>Added On</th>
                        <th scope="col">Owner</th>
                        <th>Phone</th>
                        <th>Email</th>
                        <th>Zone</th>
                        <th>Sub County</th>
                        <th>Ward</th>
                        <th>Address</th>
                        <!--                        <th>Status</th>-->
                        <th scope="col" class="text-right">Last Payment Date</th>
                        <th scope="col" class="text-right">Penalties</th>
                        <th scope="col" class="text-right">Arrears</th>
                        <th scope="col" class="text-right">Annual Rate</th>
                        <th class="text-right">Balance</th>


                        <th></th>
                      </tr>
                      </thead>
                      <tbody class="text-capitalize">
                      <tr :key="index" :value="item" v-for="(item, index) in plots">
                        <td>{{item.plotNumber}}</td>
                        <td>
                          {{item.plotSize}}
                        </td>

                        <td>{{item.createdBy}}</td>
                        <td>{{formatDate(item.dateCreated)}}</td>
                        <td>{{item.ownerNames}}</td>
                        <td>{{maskPhoneNumber(item.ownerPhoneNo)}}</td>
                        <td>{{item.ownerEmail}}</td>
                        <td>{{item.zone}}</td>
                        <td>{{item.subCountyName}}</td>
                        <td>{{item.wardName}}</td>
                        <td>{{item.plotAddress}}</td>
                        <!--                        <td>
                                                  <span v-if="item.status=='Compliant'" class="badge rounded-pill bg-success text-uppercase" key="t-new">{{item.status}}</span>
                                                  <span  v-if="item.status=='Non Compliant'" class="badge rounded-pill bg-success text-uppercase" key="t-new">{{item.status}}</span>
                                                </td>-->
                        <td>{{item.lastPaymentDate}}</td>
                        <td>{{item.penalties}}</td>
                        <td class="text-right">
                          <span class="fw-bold">KES {{numberFormat(item.outstandingArrears)}}</span>
                        </td>
                        <td class="text-right">
                          <span class="fw-bold">KES {{numberFormat(item.annualCharges)}}</span>
                        </td>
                        <td class="text-right fw-bold">
                          KES {{numberFormat(item.outstandingArrears)}}
                        </td>


                        <td>
                          <div class="dropdown">


                            <button  role="button" data-bs-toggle="dropdown" aria-haspopup="true" class="btn btn-sm btn-warning text-black waves-effect btn-label waves-light text-uppercase"><i class="mdi mdi-arrow-down-drop-circle label-icon"></i> More Options</button>

                            <div class="dropdown-menu dropdown-menu-end">
                              <a @click="selectPlot(item,'')" class="dropdown-item" href="#" data-toggle="modal" data-target=".details-modal"><i class="font-size-15 mdi mdi-eye-plus-outline me-3"></i>Plot Details</a>
                              <a  @click="selectPlot(item,'')" class="dropdown-item" href="#" data-toggle="modal" data-target="#bill-client" ><i class="font-size-15 mdi mdi mdi-receipt me-3"></i>Bill Client</a>
                              <a  @click="gotTo('plot-statements',item.plotNumber,item.zone)"  class="dropdown-item" href="javascript: void(0);"><i class="font-size-15 mdi mdi-microsoft-excel me-3"></i>Statements</a>
                              <a v-if="getRight('EDIT PLOT RENT')"  @click="selectPlot(item,'EDIT PLOT')" class="dropdown-item" href="#" data-toggle="modal" data-target=".add-plot-modal"><i class="font-size-15 mdi mdi-file-document-edit me-3"></i>Edit</a>
                              <a v-if="getRight('DELETE PLOT RENT')"  @click="deletePlot(item)" class="dropdown-item" ><i class="font-size-15 mdi mdi-delete-alert me-3"></i>Delete</a>
                              <a @click="selectPlot(item,'')" class="dropdown-item" href="#" data-toggle="modal" data-target=".plot-transfer"><i class="font-size-15 mdi mdi-arrow-expand me-3 text-primary"></i>Plot Transfer</a>
                              <a  @click="selectPlot(item,'')" class="dropdown-item" href="javascript: void(0);" data-toggle="modal" data-target="#demandNotice"><i class="font-size-15 mdi mdi-alert-outline me-2 me-3 text-danger"></i>Send Demand Notice</a>
                              <!-- <a  @click="selectPlot(item)" class="dropdown-item" href="#" data-toggle="modal" data-target=".send-sms-modal"><i class="font-size-15 mdi mdi-chat-processing me-3"></i>Send Reminder</a>-->
                            </div>
                          </div>
                        </td>
                      </tr>

                      </tbody>
                      <tfoot class="table-dark">
                      <tr>
                        <th colspan="">{{plots.length}} Plots</th>
                        <th colspan="9">22 Ha</th>
                        <th class="text-right">
                          <span class="badge rounded-pill bg-danger text-uppercase" key="t-new">Compliant</span> {{totalCompliant}}

                          <span class="badge rounded-pill bg-success text-uppercase" key="t-new">Uncompliant</span> {{totalUncomplint}}
                        </th>


                        <th class="text-right">KES {{numberFormat(totalArrears)}}</th>
                        <th class="text-right">KES {{numberFormat(totalAnnual)}}</th>
                        <th class="text-right">KES {{numberFormat(totalBalance)}}</th>

                        <th>

                        </th>

                      </tr>
                      </tfoot>


                    </table>

                    <div class="card-body px-5 d-flex flex-column justify-items-center align-items-center text-center d-flex justify-content-center"
                         v-if="loading">
                      <div class="p-5 py-0 pt-3">
                        <div class="p-5">
                          <div class="spinner-border text-info m-1" role="status">
                            <span class="sr-only text-uppercase">Loading...</span>
                          </div>
                        </div>
                      </div>
                      <h4 class="text-uppercase">Loading Data</h4>
                      <div class="col-sm-12 col-md-8 col-lg-5">
                        <p>We are fetching the data for you. It will be ready soon. Please wait
                          and have fun. Thank you for your interest.</p>

                      </div>
                    </div>
                    <div class="card-body d-sm-none d-md-block px-5 d-flex flex-column justify-items-center align-items-center text-center"
                         v-if="plot.length<1 & loading === false">
                      <div class="p-5 py-0 pt-3">
                        <img :src="'./assets/images/no-results.png'" alt="No search results"
                             class="img mb-4"/>
                      </div>
                      <h4>No Results To Show</h4>
                      <p>Sorry, we could not find any results that match your search or filter
                        criteria. Please try using different keywords or filters.</p>
                    </div>

                  </div>
                </div>

                <div class="card-footer bg-white">
                  <nav aria-label="Page navigation example "
                       class="d-flex align-items-center justify-content-between">

                    <div class="d-flex align-items-center">
                                            <span class="pr-3 pb-0 mb-0 font-13px">
                                                <span>Rows Per Page</span>
                                            </span>
                      <select class="form-select w-auto font-13px " v-model="pageSize">
                        <option value="10">10 Rows</option>
                        <option value="25">25 Rows</option>
                        <option value="50">50 Rows</option>
                        <option value="100">100 Rows</option>
                        <option value="150">150 Rows</option>
                        <option value="200">200 Rows</option>
                        <option value="10000">10,000 Rows</option>
                        <option value="50000">50,000 Rows</option>
                      </select>
                    </div>

                    <ul class="pagination">
                      <!-- add a class of disabled and an attribute of disabled when the current page is the first page -->
                      <li :class="{ disabled: currentPage === 1 }" class="page-item">
                        <a :disabled="currentPage === 1" @click="prevPage" class="page-link"
                           tabindex="-1">Previous</a>
                      </li>
                      <!-- replace the v-for directive with the pagination function call -->
                      <li :class="{ active: currentPage === page }" :key="page" class="page-item"
                          v-for="page in pagination(currentPage, totalPages, 3)">
                        <!-- check if the page is an ellipsis and display it as plain text -->
                        <span class="page-link" v-if="page === '...'">{{ page }}</span>
                        <!-- otherwise, display it as a clickable link -->
                        <a @click="goToPage(page)" class="page-link" v-else>{{ page }}</a>
                      </li>
                      <!-- add a class of disabled and an attribute of disabled when the current page is the last page -->
                      <li :class="{ disabled: currentPage === totalPages }" class="page-item">
                        <a :disabled="currentPage === totalPages" @click="nextPage"
                           class="page-link">Next</a>
                      </li>
                    </ul>


                  </nav>
                </div>

              </div>
            </div>
          </div>

          <!-- end row -->
        </div>
        <!-- container-fluid -->
      </div>
      <!-- End Page-content -->


      <!--single demand notice start -->
      <div class="modal fade" id="demandNotice" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
        <div class="modal-dialog  modal-dialog-centered modal-lg">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="staticBackdropLabel">Demand Notice</h5>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div v-if="!generateBillLoading" class="row single-notice-generator">
                <div class="col-6">
                  <address>
                    <strong class="text-capitalize">Owner's name : {{plot.ownerNames}}</strong><br>
                    Phone No. {{maskPhoneNumber(plot.ownerPhoneNo)}}<br>
                    Email: {{plot.ownerEmail}}<br>
                    <!--                    ID No. 1u just kno2345689. Car Plate No. KAB 123P <br>-->
                    <br>
                    Date {{plot.dateCreated}}

                  </address>
                </div>
                <div class="col-12">
                  <div class="table-responsive text-uppercase">
                    <table class="table table-nowrap table-bordered vertical-align-middle ">
                      <thead class="table-light">
                      <tr>
                        <th colspan="3">Property Details</th>
                      </tr>
                      </thead>

                      <tbody>
                      <tr>
                        <td  class="">
                          UPN No.: <strong>{{plot.plotNumber}}</strong>
                        </td>
                        <td  class="">
                          Block No.: <strong>{{plot.block}}</strong>
                        </td>
                        <td  class="">
                          Plot No.: <strong>{{plot.plotNumber}}</strong>
                        </td>
                      </tr>

                      <tr>
                        <td  class="">
                          Subcouny <strong>{{plot.subCountyName}}</strong>
                        </td>
                        <td  class="">
                          Ward <strong>{{plot.wardName}}</strong>
                        </td>
                        <td  class="">
                          Zone/Market <strong>{{plot.zone}}</strong>
                        </td>
                      </tr>


                      <tr>
                        <td colspan="3"  class="">
                          Rate Payer <strong>{{plot.ownerNames}}</strong>
                        </td>

                      </tr>

                      <tr>
                        <td colspan="3"  class="">
                          Physical Address <strong>{{plot.plotAddress}}</strong>
                        </td>

                      </tr>

                      <tr>
                        <td colspan="3"></td>
                      </tr>

                      <tr>
                        <th colspan="2" class="table-secondary">Details of Charges</th>
                      </tr>

                      <tr>
                        <th>
                          Last Billed For Year
                        </th>
                        <th>{{plot.dateCreated}}</th>
                      </tr>

                      <tr>
                        <th>
                          Annual Charges
                        </th>
                        <th>KES {{plot.annualCharges}}</th>
                      </tr>

                      <tr>
                        <th>
                          Current Balance
                        </th>
                        <th class="text-info">KES {{plot.outstandingArrears}}</th>
                      </tr>

                      </tbody>
                    </table>
                  </div>
                </div>

                <div class="col-12">
                  <button type="button" class="btn btn-secondary text-uppercase" data-dismiss="modal">Close</button>
                  <button @click="generateDemandBill()" type="button" class="btn btn-primary text-uppercase gen-notice">Generate Notice</button>
                </div>
              </div>

              <div class="row align-items-center justify-content-center">
                <div class="col-7">
                  <div v-if="generateBillLoading && demandNotice === false"  class="notice-loader">
                    <div class="loader-modal p-5">
                      <div class="d-flex align-items-center justify-content-center p-5 text-center flex-column">
                        <div class="spinner-border text-dark m-1" role="status">
                          <span class="sr-only fw-bold">Loading</span>
                        </div>
                        <p class="pt-4 fw-strong text-uppercase fw-bold">Generating Demand notice <br> Kindly wait</p>
                      </div>
                    </div>
                  </div>

                  <div v-if="demandNotice" class="notice-created-cont">
                    <div class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success d-non payment-panel">


                      <div class="flex-shrink-0 align-self-center  mb-4 pb-4 pt-4">
                        <div class="avatar-sm rounded-circle bg-success mini-stat-icon">
                          <span class="avatar-title rounded-circle bg-success"> <i class="mdi-check-all mdi font-size-24"></i></span>
                        </div>
                      </div>


                      <h4 class="text-black fw-bold text-uppercase">Demand Notice Sent</h4>
                      <p class="text-muted mb-4">A Demand Notice with Reference Number <strong class="text-dark">{{billDetails.billNo}}</strong> for <strong class="text-dark">KES {{billDetails.detailAmount}}</strong> has been sent to <strong class="text-dark">{{plot.ownerNames}}</strong> via mobile number <strong class="text-dark">{{plot.ownerNames}}</strong>. You may also print a physical copy to share with the client.</p>
                      <p class="text-muted mb-4">Click the button below to print the document.</p>

                      <div class="d-flex w-100 pb-2">

                        <!-- data-bs-dismiss="modal" -->
                        <a @click="printBillDemandNotice" href="javascript: void(0);" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1 text-uppercase">
                          <i class="bx bx-printer font-size-16 align-middle me-2 "></i>
                          Print Notice
                        </a>
                      </div>
                    </div>
                  </div>

                </div>
              </div>

            </div>
            <div class="modal-footer">

            </div>
          </div>
        </div>
      </div>
      <!-- single demand notice end -->


      <!-- receipting modal -->
      <div class="modal fade receipting-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered modal-full modal-fullscreen">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">Business Details</h5>
              <span
                  class="badge rounded-pill bg-success text-uppercase font-12px text-uppercase mx-3">Active
                                Certificate</span>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">
                  <div class="hori-timeline mt-4">
                    <div class="owl-carousel owl-theme  navs-carousel events timeline-carousel"
                         id="timeline-carousel">
                      <div class="item event-list">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">12 Sep 2023 10:09 AM</div>
                            <h5 class="mb-4">Application</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">Kelvin Omondi</a></p>
                          </div>
                        </div>
                      </div>

                      <div class="item event-list">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">15 Sep 2023 12:59 AM</div>
                            <h5 class="mb-4">Details Verification</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">By Kelvin Ouma</a></p>
                          </div>
                        </div>
                      </div>

                      <div class="item event-list">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">20 Sep 2023 08:23 AM</div>
                            <h5 class="mb-4">Premices Inspection</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">By Alex Kinoti</a></p>
                          </div>
                        </div>
                      </div>

                      <div class="item event-list">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">23 sep 2023 08:23 AM</div>
                            <h5 class="mb-4">Approval Of Application</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">By John King</a></p>
                          </div>
                        </div>
                      </div>

                      <div class="item event-list">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">23 sep 2023 08:25 AM</div>
                            <h5 class="mb-4">issueance Of Certifcate</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bxs-check-circle h1 text-success down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">By John King</a></p>
                          </div>
                        </div>
                      </div>

                      <div class="item event-list active">
                        <div>
                          <div class="event-date">
                            <div class="text-primary mb-1">By 31 Dec 2023 12:00 AM</div>
                            <h5 class="mb-4">Renewal</h5>
                          </div>
                          <div class="event-down-icon">
                            <i
                                class="bx bx-down-arrow-circle h1 text-info down-arrow-icon"></i>
                          </div>

                          <div class="mt-3 px-3">
                            <p class="text-muted text-capitalize"><a
                                href="user-details.html">-</a></p>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-success border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-certification font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Certificate details</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Serial
                                No.</p>
                            </td>
                            <td class="">12345</td>

                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Last Paid for</p>
                            </td>
                            <td class="">12 Jan 2023</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Expiry
                                Date</p>

                            </td>
                            <td class="">31 Dec 2023</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Days
                                to expiry</p>

                            </td>
                            <td class="">204 Days</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Printing status</p>

                            </td>
                            <td class="">Printed</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Amount
                                Paid</p>

                            </td>
                            <td class="text-uppercase text-black fw-semibold">KES 25,000
                            </td>

                          </tr>




                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-info border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-briefcase-alt-2 font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Business Details</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business
                                Name</p>
                            </td>
                            <td class="">Wireless Electronics</td>

                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Certificate of Incoporation No. </p>
                            </td>
                            <td class="">1234557768</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">KRA
                                Pin
                                No.</p>

                            </td>
                            <td class="">12345</td>

                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">VAT
                                No.
                              </p>

                            </td>
                            <td class="">12345</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business
                                Activity</p>

                            </td>
                            <td class="">Accomodation and Catering</td>
                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business
                                Sub category</p>

                            </td>
                            <td class="">Small Lodging House</td>
                          </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-warning border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bxs-user-pin font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Owner Details</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Owner's Full Names</p>
                            </td>
                            <td class="">Mr Alex Wanjala Akinyi</td>

                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                ID/PPT No.</p>
                            </td>
                            <td class="">1234557768</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Owner's Fax</p>

                            </td>
                            <td class="">12345</td>

                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Owner's Mobile No.</p>

                            </td>
                            <td class=""><a href="tel:0704549859">0704549859</a></td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Alternative Mobile No.</p>

                            </td>
                            <td class=""><a href="tel:0704549859">0704549859</a></td>

                          </tr>



                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Postal
                                Address</p>

                            </td>
                            <td class="">12-1004 Nairobi</td>
                          </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                </div>

                <div class="col-sm-12 col-md-4">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-danger border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-phone-call pin font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Location and Contact information</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div class="table-responsive">
                        <table
                            class="table table-nowrap vertical-align-middle table-striped table-sm">
                          <tbody>
                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business Mobile No.</p>
                            </td>
                            <td class=""><a href="tel:0704549859">0704549859</a></td>

                          </tr>

                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Alternative Phone No</p>
                            </td>
                            <td class=""><a href="tel:0704549859">0704549859</a></td>

                          </tr>



                          <tr
                              title="Driving Without identification plates affixed or plates not fixed in the prescribed manner">
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Business Email address</p>
                            </td>
                            <td class=""><a
                                href="mailto:email@email.com">email@email.com</a>
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold"> Sub
                                county</p>

                            </td>
                            <td class="">Homabay Town Sub county</td>

                          </tr>
                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">Ward
                              </p>

                            </td>
                            <td class=""><a href="tel:0704549859">Rodi Township Ward</a>
                            </td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Physical address/Street</p>

                            </td>
                            <td class=""><a href="tel:0704549859">Along Moi Avenue
                              opposite cooperative Bank</a></td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Building Name</p>

                            </td>
                            <td class="">ALexis complex</td>

                          </tr>

                          <tr>
                            <td>
                              <p class="the-bill-item mb-0 p-0 m-0 fw-semibold">
                                Stall/Room No.</p>

                            </td>
                            <td class="">122</td>

                          </tr>

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>


                </div>

                <div class="col-sm-12 col-md-8">
                  <div class="row">
                    <div class="col-12 mt-4">
                      <div class="bg-dark border-2 bg-soft p-2 mb-4 d-flex align-items-center">
                        <i class="bx bx-map font-28 mr-15px"></i>
                        <p class="fw-semibold mb-0 pb-0">Map View</p>

                      </div>
                    </div>
                    <div class="col-12">
                      <div>
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d46449.71551863634!2d36.67523836781685!3d-1.3353441122372378!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f0cb9ff59a4a3%3A0x4073419e11826405!2sOrbital%20Africa%20%7C%20Land%20%26%20Topo%20Survey%2C%20GPS%2C%20GPR%2C%20GIS%2C%20Mapping%2C%20Drones%2C%20Training%20%26%20Geospatial%20Solutions!5e0!3m2!1sen!2ske!4v1691859398886!5m2!1sen!2ske"
                            width="100%" height="450" style="border:0;" allowfullscreen=""
                            loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                      </div>
                    </div>
                  </div>


                </div>
              </div>

            </div>
            <div class="modal-footer">

              <a href="PrintDocs/Trade-Permit/permit.html" target="_blank" type="button"
                 class="btn btn-primary waves-effect waves-light">
                <i class="mdi mdi-printer font-size-16 align-middle me-2"></i> Print Certificate
              </a>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of receipting modal -->

      <!-- add plot modal -->
      <div class="modal fade add-plot-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-uppercase" id="myLargeModalLabel">{{title}}</h5>

              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">

                <div class="col-12">
                  <p class="fw-bold text-black text-uppercase">Plot Details:</p>
                </div>


                <div class="col-12">
                  <div class="form-group mb-3">
                    <label for="">Plot Number</label>
                    <input v-if="title !== 'EDIT PLOT'" v-model="plot.plotNumber" type="text" class="form-control" placeholder="Enter the Plot Number">
                    <input v-if="title === 'EDIT PLOT'" v-model="plot.plotNumber" type="text" class="form-control" placeholder="Enter the Plot Number" readonly>
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="">Select Sub County</label>
                    <select class="form-select  font-13px  form-control" @change="getWards(subCounty.subCountyID)"  v-model="subCounty">

                      <option v-for="(item, index) in subCounties" :key="`${index}`" :value="item"><a class="dropdown-item" href="#">{{item.subCountyName}}</a></option>
                    </select>


                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="">Select Ward</label>
                    <select class="form-select  font-13px  form-control" v-model="ward">
                      <option :key="`${index}`" :value="item" v-for="(item, index) in wards"><a
                          class="dropdown-item" href="#">{{item.wardName}}</a></option>
                    </select>

                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="">Plot Size (Ha)</label>
                    <input v-model="plot.plotSize" type="text" class="form-control" placeholder="In Ha">
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="">Longitude</label>
                    <input v-model="plot.lat" type="text" class="form-control" placeholder="Selected Longitude" readonly>
                  </div>
                </div>

                <div class="col-4">
                  <div class="form-group mb-3">
                    <label for="">Latitude</label>
                    <input v-model="plot.lng" type="text" class="form-control" placeholder="Selected Latitude" readonly>
                  </div>
                </div>

                <div class="col-12 mb-3">
                  <div class="form-group mb-3">
                    <label for="">Plot Address</label>
                    <input v-model="plot.plotAddress" type="text" class="form-control" placeholder="Enter Plot's Physical Address">
                  </div>
                </div>

                <div class="col-12">
                  <p class="fw-bold text-black text-uppercase">Owner Details:</p>
                </div>

                <div class="col-12">
                  <div class="form-group mb-3">
                    <label for="">Owner's Full Names</label>
                    <input v-model="plot.ownerNames" type="text" class="form-control" placeholder="eg John Doe">
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group mb-3">
                    <label for="">Owner's Email</label>
                    <input v-model="plot.ownerEmail" type="email" class="form-control" placeholder="Email Address">
                  </div>
                </div>

                <div class="col-6 mb-3">
                  <div class="form-group mb-3">
                    <label for="">Owner's Phone No.</label>
                    <input v-model="plot.ownerPhoneNo" type="number" class="form-control" placeholder="eg 0712345678">
                  </div>
                </div>

                <div class="col-12">
                  <p class="fw-bold text-black text-uppercase">Plot Fees Details:</p>
                </div>
                <div class="col-6 mb-3">
                  <div class="form-group mb-3">
                    <label for="">Outstanding Arrears</label>
                    <input  v-model="plot.outstandingArrears" type="number" class="form-control" placeholder="Enter the arrears">
                  </div>
                </div>
                <div class="col-6 mb-3">
                  <div class="form-group mb-3">
                    <label for="">Annual Charges</label>
                    <input v-model="plot.annualCharges" type="number" class="form-control" placeholder="Enter the annual Charges">
                  </div>
                </div>

              </div>

            </div>
            <div class="modal-footer">

              <a data-bs-dismiss="modal" @click="addPlotRent()" type="button" href="javascript: void(0);"
                 class="btn btn-primary waves-effect waves-light">
                <i class="bx bx-check font-size-16 align-middle me-2"></i> Submit
              </a>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of add plot modal -->

      <!-- uploading an excel -->
      <div class="modal fade upload-plot-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-lg modal-dialog-centered ">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Upload Plots</h5>

              <button type="button" class="btn-close" data-bs-dismiss="modal"  aria-label="Close"></button>
            </div>

            <div class="row" style="margin-left: 10px;margin-top: 10px">
              <a :href="templateURL">Download Template</a>
            </div>



            <div class="modal-body">
              <div class="row">

                <div class="row">
                  <div class="col-4">
                    <div class="form-group mb-3">
                      <label for="">Select Sub County</label>
                      <select class="form-select  font-13px  form-control" @change="getZones(subCounty.subCountyID)"  v-model="subCounty">

                        <option v-for="(item, index) in subCounties" :key="`${index}`" :value="item"><a class="dropdown-item" href="#">{{item.subCountyName}}</a></option>
                      </select>



                    </div>
                  </div>

                  <div class="col-4">
                    <div class="form-group mb-3">
                      <label for="">Select Market/Zone</label>

                      <select class="form-select font-13px  form-control" v-model="zone">
                        <option :key="`${index}`" :value="item.zone" v-for="(item, index) in zones"><a
                            class="dropdown-item" href="#">{{item.zone}}</a></option>
                      </select>


                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label for="" class="text-uppercase mb-3">Upload Excel Sheet</label>
                  </div>
                </div>
                <div class="col-12">
                  <div>

                    <div class="fallback">
                      <input  @change="handleFileChange($event)" name="file" type="file">
                    </div>
                    <div class="dz-message needsclick">
                      <div class="mb-3">
                        <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                      </div>

                      <h4>Drop files here or click to upload.</h4>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">



              <a  data-bs-dismiss="modal"  @click="uploadPlotRentToQue()"  type="button"
                  class="btn btn-primary waves-effect waves-light">
                <i class="bx bx-check font-size-16 align-middle me-2"></i> Submit
              </a>
            </div>
          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- end of uploading excel -->

      <!-- invoice details -->
      <div class="modal fade add-car-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel"
           aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="myLargeModalLabel">Usiku Sacco</h5>
              <button type="button" class="btn-close" aria-label="Close" onclick="closeModal()"></button>

            </div>
            <div class="modal-body">
              <div >
                <div class="col-12">
                  <address>
                    <strong>About Sacco:</strong><br>
                    Conatct Person: Kelvin Njuguna<br>
                    email@mail.com, 0704 549 859<br>
                    Hse No. 410, 90 Degrees By Tsavo
                    <br><br>
                    Ward, Sub County
                  </address>
                </div>
                <div class="new-vehicles-container-modal">
                  <div class="col-12">
                    <div class="py-2 mt-3">
                      <h3 class="font-size-15 fw-bold">Vehicles to Be newly registered</h3>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="">
                      <table class="table table-editable-1 align-middle table-edits">
                        <thead class="table-light">
                        <tr class="text-uppercase table-dark">
                          <th>#</th>
                          <th>Vehicle</th>
                          <th class="">Category</th>
                          <th class="">Owner</th>
                          <th>Phone No.</th>
                          <th></th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr data-id="1">
                          <td style="width: 80px">1.</td>
                          <td class="">
                            <input type="text " class="form-control " placeholder="Enter Plate No." spellcheck="false" data-ms-editor="true">
                          </td>
                          <td>
                            <select class="form-control selectpicker form-select" data-live-search="true" title="Select Vehicle Category" data-style="form-select btn border">
                              <option value="Sedan">Sedan</option>
                              <option value="SUV">SUV</option>
                              <option value="Truck">Truck</option>
                              <option value="Motorcycle">Motorcycle</option>
                              <option value="Van">Van</option>
                              <!-- Add more vehicle categories here as needed -->
                            </select>

                          </td>
                          <td class="">
                            <input type="text " class="form-control " placeholder="Owner's Name" spellcheck="false" data-ms-editor="true">
                          </td>
                          <td class="">
                            <input type="text " class="form-control " placeholder="Owner's Phone No." spellcheck="false" data-ms-editor="true">
                          </td>

                          <td></td>

                        </tr>

                        <tr class="cloneCharges d-none">
                          <td style="width: 80px " class="categoryIndex ">#</td>

                          <td class="">
                            <input type="text " class="form-control " placeholder="Enter Plate No." spellcheck="false" data-ms-editor="true">
                          </td>
                          <td>
                            <select class="form-control" data-live-search="true" title="Select Vehicle Category" data-style="form-select btn border">
                              <option value="Sedan">Sedan</option>
                              <option value="SUV">SUV</option>
                              <option value="Truck">Truck</option>
                              <option value="Motorcycle">Motorcycle</option>
                              <option value="Van">Van</option>
                              <!-- Add more vehicle categories here as needed -->
                            </select>

                          </td>
                          <td class="">
                            <input type="text " class="form-control " placeholder="Owner's Name" spellcheck="false" data-ms-editor="true">
                          </td>
                          <td class="">
                            <input type="text " class="form-control " placeholder="Owner's Phone No." spellcheck="false" data-ms-editor="true">
                          </td>


                          <td class="text-right cell-change d-flex align-items-center justify-content-end">
                            <a class="btn btn-light btn-rounded waves-effect btn-circle btn-transparent cancel-new-category-2 " title="Cancel "><i class="bx bx-x "></i></a>
                          </td>
                        </tr>


                        </tbody>
                        <tfoot>
                        <tr>
                          <td colspan="7" class="bg-light add-field-1 cursor-pointer">
                            <span @click="changeTitle('ADD NEW PLOT')" class="d-flex align-items-center "><i class="dripicons-plus mr-5 d-flex justify-content-center align-items-center font-21 "></i><span class="pl-5 ">Add A Vehicle</span></span>
                          </td>
                        </tr>
                        </tfoot>
                      </table>
                    </div>


                  </div>
                </div>

                <div class="d-none  loader-panel">
                  <div class="p-5 py-0 pt-3 d-flex justify-content-center align-items-center text-center flex-column">
                    <div class="p-5">
                      <div class="spinner-border text-info m-1" role="status">
                        <span class="sr-only text-uppercase">Loading...</span>
                      </div>
                    </div>
                    <h4 class="text-black fw-bold">Payment Being Processed</h4>
                    <div class="mb-5">Ask Client to be checking their Phone for a payment request thats currently being Processed</div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <div class="col-12 d-non">
                <div class="table-resposive p-4 px-2 pt-2 overflow-visible">
                  <table class="w-100">
                    <tbody>
                    <tr data-id="1">

                      <td class="px-3 ">
                        <div class="phone-num d-non">
                          <label>Paying Phone No.</label>
                          <input type="text " class="form-control w-100 d-flex "
                                 placeholder="Paying Phone Number" spellcheck="false"
                                 data-ms-editor="true">
                        </div>

                      </td>
                      <td class="px-3 ">
                        <div class="phone-num d-non">
                          <label>Vehicles</label>
                          <input type="text " class="form-control w-100 d-flex "
                                 placeholder="Paying Phone Number" spellcheck="false"
                                 data-ms-editor="true" disabled value="4 Vehicles">
                        </div>

                      </td>
                      <td class="px-3">
                        <label>Computed Registration Amount</label>
                        <input type="text " class="form-control w-100 d-flex"
                               placeholder="KES" spellcheck="false" data-ms-editor="true" disabled value="8000">

                      </td>



                    </tr>
                    </tbody>
                  </table>

                </div>
              </div>
              <div class="float-end">

                <a href="javascript: void(0);"
                   class="btn btn-primary w-md waves-effect waves-light reg-payment">Send Payment Request</a>
              </div>
            </div>

          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>
      <!-- invoice details -->


      <footer class="footer ">
        <div class="container-fluid ">
          <div class="row ">
            <div class="col-sm-6 ">

            </div>

          </div>
        </div>
      </footer>

      <!-- PLOT details -->

      <div class="modal fade details-modal" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg  modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title text-uppercase" id="myLargeModalLabel">Plot Details</h5>
              <button type="button" class="btn-close" data-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <div class="row">
                <div class="col-12">

                  <div class="mt-3">
                    <table class="table align-middle table-nowrap table-hover dt-responsive contacts-table dataTable no-footer table-sm" id="datatable-buttons">

                      <tbody>
                      <tr>

                        <td>
                          Plot No
                        </td>
                        <td class="text-uppercase">
                          {{plot.plotNumber}}
                        </td>
                      </tr>

                      <tr>

                        <td>
                          Owner
                        </td>
                        <td class="text-uppercase">
                          {{plot.ownerNames}}
                        </td>
                      </tr>

                      <tr>

                        <td>
                          Phone Number
                        </td>
                        <td class="">
                          {{maskPhoneNumber(plot.ownerPhoneNo)}}

                        </td>
                      </tr>

                      <tr>

                        <td>
                          Email Address
                        </td>
                        <td class="text-capitalize">
                          {{plot.ownerEmail}}

                        </td>
                      </tr>

                      <tr>

                        <td>
                          Sub County
                        </td>
                        <td class="text-capitalize">
                          {{plot.subCounty}}
                        </td>
                      </tr>


                      <tr>

                        <td>
                          Ward
                        </td>
                        <td class="text-capitalize">
                          {{plot.wardName}}
                        </td>
                      </tr>

                      <tr>

                        <td>
                          Physical Address
                        </td>
                        <td class="text-uppercase">
                          {{plot.plotAddress}}
                        </td>
                      </tr>

                      <tr>

                        <td>
                          Added By
                        </td>
                        <td class="text-uppercase">
                          {{plot.createdBy}} on {{formatDate(plot.dateCreated)}}
                        </td>
                      </tr>

                      <!--                      <tr>

                                              <td>
                                                Last Edited By
                                              </td>
                                              <td class="text-uppercase">
                                                Alex Wanjala on 22 Jan 2024 at 5:35 PM (Fields edited Name, Plot No.)
                                              </td>
                                            </tr>-->

                      <!--                      <tr>

                                              <td>
                                                Most Recent Payment
                                              </td>
                                              <td class="text-uppercase">
                                                KES 4,000 On 22 Jan 2024 AT 2:45 PM By Kelvin Using Mpesa(SWERTGHH)
                                              </td>
                                            </tr>-->

                      <tr>

                        <td>
                          Compliance Status
                        </td>
                        <td class="text-uppercase">
                          {{plot.status}}
                        </td>
                      </tr>

                      <tr>

                        <td>
                          Annual Payable Fees
                        </td>
                        <td class="text-uppercase fw-bold text-black">
                          KES {{numberFormat(plot.annualCharges)}}
                        </td>
                      </tr>

                      <tr>

                        <td>
                          Outstanding Balances
                        </td>
                        <td class="text-uppercase fw-bold text-danger">
                          KES {{numberFormat(plot.outstandingArrears)}}
                        </td>
                      </tr>
                      </tbody>

                    </table>


                  </div>


                </div>
              </div>
            </div>
            <div class="modal-footer">

              <a href="javascript: void(0);" data-dismiss="modal" aria-label="Close" class="btn btn-primary w-md waves-effect waves-light w-100 text-uppercase">Close Details</a>
            </div>

          </div>
          <!-- /.modal-content -->
        </div>
        <!-- /.modal-dialog -->
      </div>


      <!-- end of land details -->

      <!-- billing on the spot -->
      <div class="modal fade" id="bill-client" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header d-none">
              <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


              <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal" data-dismiss="modal"  aria-label="Close">
                            <span class="avatar-title bg-transparent text-reset font-18px">
                                <i class="bx bx-x"></i>
                            </span>
              </button>

            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-between align-items-center">
                <span class="badge  badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


                <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle pull-right close-modal" data-dismiss="modal" aria-label="Close">
                            <span class="avatar-title bg-transparent text-reset font-18px">
                                <i class="bx bx-x"></i>
                            </span>
                </button>
              </div>

              <div class="payment-panel-parent">

                <div class="payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success d-non payment-panel">
                  <div class="success-image mb-4 pb-4 pt-4">
                    <img src="assets/images/bills.svg" height="100" alt="">
                  </div>


                  <h4 class="text-black fw-bold">Create Bill</h4>


                  <div class="border-1 invoice-form p-4">

                    <div class="form-group mb-4">

                      <div class="row">
                        <div class="col-6">
                          <label for="" class="text-uppercase">Plot Number:</label>
                        </div>
                        <div class="col-6">
                          <p class="text-black fw-bold">{{ plot.plotNumber }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <label for="" class="text-uppercase">Onwer:</label>
                        </div>
                        <div class="col-6">
                          <p class="text-black fw-bold">{{ plot.ownerNames }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <label for="" class="text-uppercase">Annual Rate:</label>
                        </div>
                        <div class="col-6">
                          <p class="text-black fw-bold">KES {{ plot.annualCharges }}</p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-6">
                          <label for="" class="text-uppercase">Arreas:</label>
                        </div>
                        <div class="col-6">
                          <p class="text-black fw-bold">KES {{ plot.outstandingArrears }}</p>
                        </div>
                      </div>
                    </div>

                    <p class="text-muted mb-4">Please input the desired payment amount based on the outstanding arreas or what the client wishes to pay</p>

                    <div class="col-12">

                      <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label">Income Type</label>

                        <select @change="selectedIncomeType($event)"  class="form-control show-tick" data-live-search="true" >
                          <option v-for="(item, index) in billingModule.incomeTypes" :value="JSON.stringify(item)" :key="index">
                            {{ item.incomeTypeDescription }}
                          </option>
                        </select>

                      </div>

                    </div>
                    <div class="col-12">
                      <div class="mb-3">
                        <label for="formrow-firstname-input" class="form-label">Fee type</label>
                        <select @change="selectedFeeAndCharge($event)" class="form-control bg-white" title="Select Traffic offence" data-live-search="true" >
                          <option v-for="(item, index) in feesAndCharges" :value="JSON.stringify(item)" :key="index">
                            {{ item.feeDescription }}
                          </option>
                        </select>
                      </div>
                    </div>

                    <div class="col-12">
                      <div class="row">
                        <div class="col-12">
                          <label for="formrow-quantity-input" class="form-label">Amount to Invoice</label>
                          <input v-model="quantity" type="text" class="form-control" id="formrow-quantity-input" placeholder="Amount">
                        </div>
                        <!--                        <div class="col-6" d-done >
                                                  <label for="formrow-amount-input" class="form-label">Amount</label>
                                                  <input v-model="amount" type="text" class="form-control" id="formrow-amount-input" placeholder="Bill Item amount" readonly>
                                                </div>-->
                      </div>
                    </div>
                  </div>


                  <div class="d-flex w-100 pb-2" style="margin-top: 20px">

                    <button @click="generateBill()" data-toggle="modal" data-target="#payment-modal" data-dismiss="modal"  type="button" class="btn btn-primary btn-block  me-3 flex-grow-1 print-bill-btn">
                      <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                      Create Invoice
                    </button>


                  </div>
                </div>


              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- billing on the spot -->

      <!-- payment modal -->
      <div class="modal fade" id="payment-modal" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header d-none">
              <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


              <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal" data-dismiss="modal"  aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
              </button>

            </div>
            <div class="modal-body">
              <div class="d-flex justify-content-between align-items-center">
                <span class="badge  badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white">Receive Payment</span>


                <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle pull-right close-modal" data-dismiss="modal" aria-label="Close">
                        <span class="avatar-title bg-transparent text-reset font-18px">
                            <i class="bx bx-x"></i>
                        </span>
                </button>
              </div>

              <div>

                <div v-if="paymentPanel" class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
                  <div class="success-image mb-4 pb-4 pt-4">
                    <img src="assets/images/bills.svg" height="200" alt="">
                  </div>
                  <h4 class="text-black fw-bold">{{message}}</h4>
                  <p class="text-muted mb-4">The bill has been created Successfully, you can print the bill or opt to receive payment for this bill.</p>
                  <div class="d-flex w-100 pb-2">
                    <button @click="showPaymentMethod()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                      <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                      Receive Payment
                    </button>
                    <a @click="printBill()" target="_blank" data-dismiss="" type="button" class="btn btn-outline-secondary waves-effect dismin-modal">Print Bill</a>
                  </div>
                </div>

                <div v-if="paymentMethod" class="send-method payment-panel ">
                  <label for="" class="mb-0 pb-0">Payment Method</label>
                  <p><small class="text-muted">How would you like to send this money?</small></p>

                  <div>
                    <div class="accordion" id="accordionExample">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button fw-medium" type="button" data-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            <div class="flex-shrink-0 me-3">
                              <img class="rounded-circle" src="assets/images/users/mpesa.jpg" alt="Mobile Money" height="65">
                            </div>
                            <div class="d-flex flex-column">
                              <p class="m-0 p-0 text-uppercase fw-semibold">MPESA</p>
                              <p class="mb-0 p-0"> <small>A payment request will be sent directly to the customer</small></p>
                            </div>
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <div>
                              <p>
                                A payment request of <strong class="text-black">KES {{billDetails.billBalance}}</strong> will be sent to the MPESA number you enter below.
                              </p>
                            </div>
                            <div class="form-group">
                              <label for="">MPESA Number</label>
                              <div class="form-floating mb-3">
                                <input v-model="phoneNumber" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                <label for="floatingnameInput">Phone No.</label>
                              </div>

                              <button @click="showTransactionSummary('MPESA')" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                                <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                                Send Payment Request
                              </button>

                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                          <button class="accordion-button fw-medium collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                            <div class="flex-shrink-0 me-3">
                              <img class="rounded-circle" src="assets/images/users/money.jpg" alt="Mobile Money" height="65">
                            </div>
                            <div class="d-flex flex-column">
                              <p class="m-0 p-0 text-uppercase fw-semibold">bank Receipting</p>
                              <p class="mb-0 p-0"> <small>Enter the receipt number here to confirm payment for this transaction.</small></p>

                            </div>
                          </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                          <div class="accordion-body">
                            <div class="form-group">
                              <label for="">Enter The Bank's Receipt Number</label>
                              <div class="form-floating mb-3">
                                <input v-model="paymentData.bankReceiptNo" type="text" class="form-control font-21 text-success form-control-lg" id="phone-input" placeholder="Enter Name">
                                <label for="floatingnameInput">Bank Ref No.</label>
                              </div>

                              <button @click="showTransactionSummary('BANK')" class="btn w-100 btn-warning mt-3 btn-bank">
                                Continue
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>

                  </div>
                </div>

                <div v-if="transactionSummary" class="transaction-summary payment-panel">
                  <label for="">Transaction Breakdown</label>
                  <div class="border p-4 rounded ">
                    <div class="row">
                      <div class="col-lg-6">
                        <div class="text-muted ">
                          BillO NO.
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          {{billDetails.billNo}}
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Bill For
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          {{billDetails.incomeTypeDescription}}
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Payment Method
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          {{paymentData.paymentMode}}
                        </div>
                      </div>

                      <div class="col-lg-6">
                        <div class="text-muted mt-2">
                          Transaction Amount
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end">
                        <div class=" text-right text-black">
                          KES {{billDetails.billBalance}}
                        </div>
                      </div>


                      <div class="col-12 text-black"><hr class="mb-0 pb-0"></div>

                      <div class="col-lg-6 text-uppercase">
                        <div class="text-muted mt-2 font-21">
                          Total
                        </div>
                      </div>

                      <div class="col-lg-6 align-self-end text-uppercase">
                        <div class=" text-right text-black font-21 fw-bold">
                          KES {{billDetails.billBalance}}
                        </div>
                      </div>
                    </div>

                    <div class="mpesa-confirmation">
                      <div v-if="paymentData.paymentMode === 'MPESA'">
                        <p class="text-muted mt-2">A payment request of <strong class="text-black">KES {{billDetails.billBalance}}</strong> will be sent to <strong class="text-black"> phone number ({{ phoneNumber }})</strong> soon after you click the <strong class="text-black text-capitalize">Send Request</strong> button bellow.
                          <br>
                          <br>
                          Remember to <strong class="text-black">Check your phone</strong> to confirm payment by entering your Mpesa pin.</p>
                      </div>

                      <div>{{message}}  </div>
                      <br>
                      <button @click="makePayment()" class="btn btn-primary btn-flex flex-grow-1 waves-effect btn-send waves-light text-center w-100">
                        <div class="d-flex justify-content-center align-items-center">
                          <div class="stk-timer-container d-none justify-content-center align-items-center">
                            <span class="mdi mdi-timer-outline font-16px"></span>
                            <span class="stk-timer px-2"></span>
                          </div>
                          <div class="justify-content-center align-items-center d-flex">
                            <span class="px-2">Send Request</span>
                            <div class="flip-x"><i class="mdi mdi-reply ms-3 font-16px"></i></div>
                          </div>
                        </div>
                      </button>
                    </div>


                  </div>
                </div>

                <div v-if="transactionCompleted" class="text-center d-flex flex-column justify-content-around align-items-center sent-success payment-panel">
                  <div class="success-image mb-4">
                    <img src="assets/images/payment-confirmation-images/sent.svg" height="200" alt="">
                  </div>
                  <h4 class="text-blink-primary fw-bold">Transaction Complete</h4>
                  <p class="text-muted mb-4"><strong class="text-black">KES {{receiptDetails.receiptAmount}}</strong> has been received for <strong class="text-black">Bill No. {{receiptDetails.billNo}}</strong></p>
                  <button @click="printReceipt()" type="button" class="btn btn-primary btn-block  me-3 flex-grow-1">
                    <i class="mdi-cash-check mdi font-size-16 align-middle me-2 "></i>
                    Print Receipt
                  </button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- end of payment modal -->


      <!-- doing plot transfer -->
      <div class="modal fade plot-transfer" id="" data-backdrop="static" data-keyboard="false" tabindex="-1" role="dialog" aria-bs-labelledby="exampleModalCenterTitle" aria-bs-hidden="true" data-bs-keyboard="false" data-bs-backdrop="static">
        <div class="modal-dialog modal-dialog-centered " role="document">
          <div class="modal-content">
            <div class="modal-header ">
              <span class="badge badge-soft-success text-uppercase badge font-12px bg-primary-blink text-white d-none">Plot Transfer for Plot No. {{plot.plotNumber}}</span>

              <h4 class="modal-title">Plot Transfer for Plot No. {{plot.plotNumber}}</h4>

              <button type="button" class="btn btn-light position-relative p-0 avatar-xs rounded-circle close-modal" data-dismiss="modal"  aria-label="Close">
                            <span class="avatar-title bg-transparent text-reset font-18px">
                                <i class="bx bx-x"></i>
                            </span>
              </button>

            </div>
            <div class="modal-body">
              <div class="row d-none">
                <div class="col-6 mb-3">
                  <label for="">SUb County</label>
                  <input type="text" class="form-control" placeholder="sub County" disabled value="Homa Bay town">
                </div>

                <div class="col-6 mb-3">
                  <label for="">Market/Zone</label>
                  <input type="text" class="form-control" placeholder="sub County" value="Soko Mjinga" disabled>
                </div>

                <div class="col-6 mb-3">
                  <label for="">Owner's Name</label>
                  <input type="text" class="form-control" placeholder="sub County" value="Enter Owner's Name" title="Enter ">

                </div>
              </div>

              <div class="row">
                <div v-if="!invoiceDetails" class="col-12 mb-3 transafer-option-selector">
                  <label for="">Transfer Option</label>
                  <select v-model="transferOption" name="" class="form-select transfer-select" id="">
                    <option value="" disabled>Select an Option</option>
                    <option value="bill-new">Generate Invoice</option>
                    <option value="paid invoice">Paid Invoice</option>

                  </select>
                </div>


                <div class="col-12">

                  <div v-if="message !==null" class="alert alert-danger" role="alert">
                    {{message}}
                  </div>
                  <br/>



                  <div v-if="transferOption == 'paid invoice' && !invoiceDetails" class="row invoice-number-input">
                    <div class="col-12 mb-3">
                      <label for="">Invoice Number</label>
                      <input v-model="billNo" type="text" class="form-control invoice-num-input" placeholder="Enter Bill/Invoice Number">
                    </div>

                    <div class="col-12">
                      <div class="alert alert-success d-none" role="alert">
                        <i class="mdi mdi-check-all me-2 me-2"></i>  Corresponding Bill Found
                      </div>

                      <div class="alert alert-danger d-none" role="alert">
                        <i class="mdi mdi-block-helper me-2"></i>  The invoice found is not applicable
                      </div>

                      <div class="alert alert-danger d-none" role="alert">
                        <i class="mdi mdi-alert-outline me-2 me-2"></i>  Corresponding Bill Not Found
                      </div>
                    </div>
                    <div class="col-12 mt-3">
                      <button @click="getBill()" class="btn btn-primary w-100 invoice-search">
                        <div v-if="loading" class="spinner-border text-white m-0" height="20px" width="20px" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>

                        <div><i class="bx bx-search"></i> Invoice Search</div>
                      </button>
                    </div>
                  </div>


                  <div  class="new-billing-transfer d-none">

                  </div>


                  <div v-if="invoiceDetails ===true && captureOwner === false && transferSuccess===false" class="row invoice-results">
                    <div class="col-12 mb-3">
                      <table class="table table-sm table-striped">
                        <tr>
                          <th>Invoice Number</th>
                          <td>{{billDetail.billNo}}</td>
                        </tr>

                        <tr>
                          <th>invoiced By</th>
                          <td>{{billDetail.names}}</td>
                        </tr>

                        <tr>
                          <th>Date Billed</th>
                          <td>{{formatDate(billDetail.dateCreated)}}</td>
                        </tr>



                        <tr>
                          <th>Ref No.</th>
                          <td>{{billDetail.billNo}}</td>
                        </tr>


                        <tr>
                          <th>Bill Amount</th>
                          <td>KES {{billDetail.detailAmount}}</td>
                        </tr>

                        <tr>
                          <th>Paid Amount</th>
                          <td>KES {{billDetail.receiptAmount}}</td>
                        </tr>

                        <tr>
                          <th>Balance</th>
                          <td>KES {{billDetail.billBalance}}</td>
                        </tr>
                      </table>
                    </div>

                    <div class="col-12">
                      <div class="d-flex">
                        <button class="btn btn-danger text-uppercase me-3 transfer-cancel">Cancel</button>
                        <button @click="captureOwnerDetails()" class="btn btn-primary flex-grow-1 text-uppercase transafer-nxt">Next</button>
                      </div>
                    </div>
                  </div>

                  <div v-if="captureOwner && !transferSuccess" class="row new-owner-form">
                    <div class="col-12">
                      <div class="alert alert-secondary fw-bold mb-4" role="alert">
                        Please fill in the details below for the new owner. Note that any changes made will be subject to approval once payment is completed/Cleared.
                      </div>
                    </div>

                    <div class="col-12 mb-3">
                      <label for="">Owner's Name <strong class="fw-bold text-danger">*</strong></label>
                      <input v-model="ownerName" type="" class="form-control" placeholder="New Owner's Name" title="Enter new owner's Full Names" name="">
                    </div>

                    <div class="col-12 mb-3">
                      <label for="">Owner's ID Number <strong class="fw-bold text-danger">*</strong></label>
                      <input v-model="ownerID" type="" class="form-control" placeholder="Enter ID Number" title="Enter new owner's ID Number" name="">
                    </div>

                    <div class="col-12 mb-3">
                      <label for="">Phone Number <strong class="fw-bold text-danger">*</strong></label>
                      <input v-model="ownerPhone" type="" class="form-control" placeholder="Enter owner's Phone" title="Enter new owner's Phone Number" name="">
                    </div>

                    <div class="col-12 mb-3">
                      <label for="">Email <strong class="fw-bold text-danger">*</strong></label>
                      <input v-model="ownerEmail" type="" class="form-control" placeholder="Enter owner's Email" title="Enter new owner's Phone Number" name="">
                    </div>

                    <div class="col-12 mb-4 ">
                      <div class="border-1 border border-secondary p-4 ">
                        <h5 class="my-0 text-dark mb-1">Minutes Details</h5>
                        <p class="mb-4">Use this section to detail the minutes that led to the approval of the transfer.</p>
                        <div class="row">
                          <div class="col-12 mb-4">
                            <label for="">Minute Number <strong class="fw-bold text-danger">*</strong></label>
                            <input v-model="minuteNumber" type="" class="form-control" placeholder="Enter owner's Phone" title="Enter Minutes Number" name="">
                          </div>

                          <div class="col-12">
                            <label for="">Minutes File <strong class="fw-bold text-danger">*</strong></label>
                            <input @change="handleFileChange($event)" class="form-control" id="formFileSm" type="file" placeholder="Select File">

                          </div>

                        </div>
                      </div>
                    </div>

                    <div class="col-12 mt-3">
                      <button @click="transferRequest()" class="btn btn-primary w-100 finish-transfer text-uppercase" >
                        <div v-if="loading" class="spinner-border text-white m-0" height="20px" width="20px" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>

                        <div><i class="mdi mdi-check"></i>Finish</div>
                      </button>
                    </div>
                  </div>

                  <div v-if="transferSuccess" class="row transfer-successful">
                    <div class="text-center payment-active-panel d-flex flex-column justify-content-around align-items-center sent-success d-non payment-panel">


                      <div class="flex-shrink-0 align-self-center  mb-4 pb-4 pt-4">
                        <div class="avatar-sm rounded-circle bg-success mini-stat-icon">
                                            <span class="avatar-title rounded-circle bg-success">
                                                <i class="mdi-check-all mdi font-size-24"></i>
                                            </span>
                        </div>
                      </div>


                      <h4 class="text-black fw-bold text-uppercase">Transfer Request Made</h4>
                      <p class="text-muted mb-4">
                        The property transfer request for <strong class="fw-bold">Plot Number {{plot.plotNumber}}</strong> has been successfully completed and is pending approval.
                      </p>
                      <p class="text-muted mb-4">Click Below to download the respective Receipt.</p>

                      <div class="d-flex w-100 pb-4">

                        <!-- data-bs-dismiss="modal" -->
                        <a @click="printBill" href="javascript: void(0);" target="new"  class="btn btn-primary btn-block  me-3 flex-grow-1 text-uppercase download-receipt-transfer">
                          <i class="bx bx-printer font-size-16 align-middle me-2 "></i>
                          Download Receipt
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer d-flex d-none bill-modal-footer">
              <button href="javascript: void(0);" disabled class="btn btn-outline-light waves-effect waves-light payment-prev"> <i class="mdi mdi-arrow-left ms-1"></i> Previouse </button>
              <button href="javascript: void(0);" class="btn btn-primary waves-effect waves-light payment-next">Next <i class="mdi mdi-arrow-right ms-1"></i></button>
              <button href="javascript: void(0);" class="btn btn-primary btn-flex flex-grow-1 waves-effect waves-light text-center d-none">
                <div class="d-flex justify-content-center align-items-center"> <span>Send Money</span> <div class="flip-x"><i class="mdi mdi-reply flip-x ms-3 font-16px"></i></div></div>
              </button>
            </div>
          </div>
        </div>
      </div>



    </div>
    <!-- end main content-->

  </div>
  <!-- END layout-wrapper -->

  <!-- Right bar overlay-->
  <div class="rightbar-overlay "></div>



  </body>



</template>

<script>
/* eslint-disable */
import NavigationBar from "@/components/Navigation.vue";
import moment from "moment/moment";
import {
  authUrl,
  base,
  biller,
  execute,
  execute2,
  executeDownload,
  executeJson,
  getRight,
  paysol,
  plotRent
} from "@/api";
import router from "@/router";
import ToolBar from "@/components/parking/ToolBar.vue";
import ToolBarPlot from "@/components/plotrent/ToolBar.vue";

export default {
  name: "PlotRent",
  components: {ToolBarPlot, ToolBar, NavigationBar},
  data() {
    return {

      transferSuccess: false,
      ownerEmail:'',
      ownerPhone:'',
      ownerID:'',
      ownerName:'',
      minuteNumber:'',
      captureOwner: false,
      invoiceDetails: false,
      billNo:'',
      transferOption:'',
      demandNotice: false,
      generateBillLoading: false,
      templateURL: base+"/templates/PLOT_DATA_TEMPLATE.xlsx",
      title:'',
      feeID:'',
      amount:0,
      unitFeeAmount:0,
      quantity: 1,
      subCountyName:'',
      wardName:'',
      dateFrom:'',
      dateTo:'',
      searchValue:"",

      currentPage: 1,
      // set the total number of pages as 10 (you can change this according to your data source)
      totalPages: 0,
      // set the number of items per page as 5 (you can change this according to your preference)
      pageSize: 10,
      download:'Download Report',
      loading: false,
      keyword:'',
      color:'alert-success',

      subCountyID:'',
      wardID:'',
      subCounties:[{
        subCountyID: "",
        subCountyName: ""
      }],
      wards:[{
        wardID: "",
        wardName: ""
      }],
      subCounty: {
        id:"",
        countyID:"",
        countyName:"",
        subCountyID:"",
        subCountyName:"",
        wardID:"",
        wardName:"",
        status:""
      },
      ward:{
        id:"",
        countyID:"",
        countyName:"",
        subCountyID:"",
        subCountyName:"",
        wardID:"",
        wardName:"",
        status:""
      },

      billingModule:{
        incomeType:{
          incomeTypeId: null,
          incomeTypeDescription:null,
          incomeTypePrefix: null,
          fundDescription: null,
          status: null
        },
        incomeTypes:[{
          incomeTypeId: null,
          incomeTypeDescription:null,
          incomeTypePrefix: null,
          fundDescription: null,
          status: null}]
      },

      plots: [{
        id: null,
        plotNumber: null,
        subCountyName:  null,
        subCountyID: null,
        wardName: null,
        wardID: null,
        plotSize: null,
        lat:  null,
        lng: null,
        plotAddress:  null,
        ownerNames:  null,
        ownerEmail:  null,
        ownerPhoneNo: null,
        outstandingArrears: null,
        annualCharges: null,
        createdBy: null,
        createdByIDNo: null,
        dateCreated: null,
        status: null,
        zone:null,
        penalties: null,
        lastPaymentDate: null
      }],
      plot: {
        id: null,
        block:null,
        upn:null,
        plotNumber: null,
        subCountyName:  null,
        subCountyID: null,
        wardName: null,
        wardID: null,
        plotSize: null,
        lat:  null,
        lng: null,
        plotAddress:  null,
        ownerNames:  null,
        ownerEmail:  null,
        ownerPhoneNo: null,
        outstandingArrears: null,
        annualCharges: null,
        createdBy: null,
        createdByIDNo: null,
        dateCreated: null,
        status: null,
        zone:null,
        penalties: null,
        lastPaymentDate: null

      },
      totalUncomplint:0,
      totalCompliant:0,
      totalArrears:0,
      totalAnnual:0,
      totalBalance:0,
      feesAndCharges:[{
        feeId: null,
        feeDescription: null,
        unitOfMeasure: null,
        unitFeeAmount: null,
        accountNo: null,
        incomeTypeId: null,
        feeType: null,
        accountDesc:null ,
        costCenterNo:null,
        zone: null,
        typeDescription: null
      }],
      selectedPlots:[
        {
          id: null,
          plotNumber: null,
          subCounty:  null,
          subCountyID: null,
          wardName: null,
          wardID: null,
          plotSize: null,
          lat:  null,
          lng: null,
          plotAddress:  null,
          ownerNames:  null,
          ownerEmail:  null,
          ownerPhoneNo: null,
          outstandingArrears: null,
          annualCharges: null,
          createdBy: null,
          createdByIDNo: null,
          dateCreated: null,
          status: null,
        }
      ],

      totalAmount:0,
      message:null,
      feesAndChargeItems:[{
        feeId: null,
        feeDescription: null,
        unitOfMeasure: null,
        unitFeeAmount: null,
        accountNo: null,
        incomeTypeId: null,
        feeType: null,
        accountDesc:null ,
        costCenterNo:null,
        zone: null,
        typeDescription: null,
        customer: null
      }],
      billItem:[],
      billDetails:{
        id:'',
        billNo:'',
        incomeTypeID:'',
        incomeTypeDescription:'',
        costCenterNo:'',
        accountNo:'',
        feeID:'',
        detailAmount:'',
        receiptAmount:'',
        billBalance:'',
        wardID:'',
        subCountyID:'',
        status:'',
        dateCreated:''
      },
      billInfo:[],
      paymentData: {
        bankReceiptNo: null,
        accNo: null,
        payBillNo: null,
        amount: null,
        phoneNumber: null,
        success: false,
        paymentMode:null,
        sessionNames:null,
        sessionIdNo:null,
        sessionPhoneNumber:null
      },
      alert:{
        success: false,
        info: false,
        failed:false
      },

      transactionCompleted: false,
      transactionSummary: false,
      paymentPanel: true,
      paymentMethod: false,
      zone:'',
      zones: [],
      summary:{
        count:0
      },
      billDetail: {
        id: null,
        billNo: null,
        incomeTypeID:null,
        incomeTypeDescription:null,
        costCenterNo: null,
        accountNo: null,
        feeID: null,
        detailAmount: null,
        receiptAmount: null,
        billBalance: null,
        wardID:null,
        subCountyID: null,
        status: null,
        dateCreated: null,
        names:null
      },
    }


  },
  watch:{
    pageSize(newPageSize) {
      this.getPlots()
      // get the total number of items from your data source (you can change this according to your logic)
      const totalItems = this.summary.count;
      // calculate the new total pages by dividing the total items by the new page size and rounding up
      const newTotalPages = Math.ceil(totalItems / newPageSize);
      // update the totalPages data property with the new value
      this.totalPages = newTotalPages;
      // reset the current page to 1
      this.currentPage = 1;

    },

    subCounty: function () {
      this.subCountyID =  this.subCounty.subCountyID
      this.subCountyName =  this.subCounty.subCountyName
      this.getWards()
      this.getPlots()

    } ,
    ward: function () {
      this.wardID = this.ward.wardID
      this.wardName = this.ward.wardName
      this.getPlots()
    },
    quantity: function () {
      this.amount = this.quantity *  this.unitFeeAmount;
    }
  },
  mounted() {

    this.menuAccess()

    this.getPlots()
    this.getSubCounties()

    this.$watch('keyword', function(newValue) {
      this.keyword = newValue;
      this.getPlots()
    })
    this.$watch('dateFrom', function() {
      if(this.dateFrom==='' || this.dateTo===''){

      }else{
        this.getPlots()
      }

    })
    this.$watch('dateTo', function() {
      if(this.dateFrom==='' || this.dateTo===''){

      }else{
        this.getBusinessData()
      }
    })


    this.subCountyID =  sessionStorage.getItem("sessionSubCountyID")
    this.wardID =  sessionStorage.getItem("sessionWardID")
    this.getPlots()
  },
  computed:{
    // calculate the start index of the items for the current page
    startIndex() {
      return (this.currentPage - 1) * this.pageSize;
    },
    // calculate the end index of the items for the current page
    endIndex() {
      return this.currentPage * this.pageSize;
    },

  },
  methods: {

    maskPhoneNumber(input) {
      if (!input || typeof input !== 'string') {
        return input; // Return as is if input is null, undefined, or not a string
      }

      const digits = input.replace(/\D/g, '');

      if (digits.length > 4) {
        return input.slice(0, 2) + input.slice(2, -2).replace(/\d/g, '*') + input.slice(-2);
      }

      return input; // Return as is if not a phone number or too short
    },

    menuAccess(){

      const data = new FormData();
      data.append("function", "menuAccess");
      data.append("menu", "Plot Rent");
      data.append("sessionNames", sessionStorage.getItem("sessionNames"));
      data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
      execute(data,biller)
          .then((res) => {
            // alert(res.data.message);
          })
          .catch((e) => {
            // alert(e.message);
          });

    },

    //pagination code
    pagination(currentPage, totalPages, maxVisibleButtons) {
      //initialize an empty array for the pages
      let pages = [];
      //calculate the start and end indices of the visible buttons
      let start = currentPage - Math.floor(maxVisibleButtons / 2);
      let end = currentPage + Math.floor(maxVisibleButtons / 2);
      //adjust the start and end indices if they are out of bounds
      if (start < 1) {
        start = 1;
        end = start + maxVisibleButtons - 1;
        if (end > totalPages) {
          end = totalPages;
        }
      }
      if (end > totalPages) {
        end = totalPages;
        start = end - maxVisibleButtons + 1;
        if (start < 1) {
          start = 1;
        }
      }
      //loop through the start and end indices and push the pages to the array
      for (let i = start; i <= end; i++) {
        pages.push(i);
      }
      //add an ellipsis at the beginning if there are more pages before the start index
      if (start > 1) {
        pages.unshift("...");
      }
      //add an ellipsis at the end if there are more pages after the end index
      if (end < totalPages) {
        pages.push("...");
      }
// return the array of pages
      return pages;
    },
    // define a method to go to the previous page
    prevPage() {
      // check if the current page is not the first page
      if (this.currentPage > 1) {
        // decrement the current page by 1
        this.currentPage--;
        this.getPlots()
      }
    },
    // define a method to go to the next page
    nextPage() {
      // check if the current page is not the last page
      if (this.currentPage < this.totalPages) {
        // increment the current page by 1
        this.currentPage++;
        this.getPlots()
      }
    },
    // define a method to go to a specific page
    goToPage(page) {
      // check if the page is valid and not equal to the current page
      if (page > 0 && page <= this.totalPages && page !== this.currentPage) {
        // set the current page to the given page
        this.currentPage = page;
        this.getPlots()
      }
    },

    formatDate(date) {
      let d = new Date(date);
      let day = d.getDate();
      let month = d.toLocaleString('en-US', { month: 'short' });
      let year = d.getFullYear();

      let suffix = '';
      if (day % 10 === 1 && day !== 11) {
        suffix = 'st';
      } else if (day % 10 === 2 && day !== 12) {
        suffix = 'nd';
      } else if (day % 10 === 3 && day !== 13) {
        suffix = 'rd';
      } else {
        suffix = 'th';
      }
      return `${day}${suffix} ${month} ${year}`;
    },
    formattedTime(time) {
      return moment(time).format('hh:mm A')
    },
    numberFormat(num) {
      const britishNumberFormatter = new Intl.NumberFormat("en-GB");
      return britishNumberFormatter.format(num)
    },
    getSubCounties(){
      const data = new FormData();
      data.append("function", "getSubCounty");
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.subCounties =res.data.data.subCounties

              //preselection
              this.subCounties.forEach((value, index) => {
                if (this.selectedUser.subCountyID === value.subCountyID) {
                  this.subCounty = this.subCounties[index];
                }
              });

            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    getWards(){
      this.wards =[]
      this.ward = ''
      const data = new FormData();
      data.append("function", "getWards");
      data.append("subCountyID", this.subCounty.subCountyID);
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.wards =res.data.data.wards

              //preselection
              this.wards.forEach((value, index) => {
                if (this.selectedUser.wardID === value.wardID) {
                  this.ward = this.wards[index];
                }
              });

            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    changeTitle(){
      this.title ="ADD NEW PLOT"
    },


    selectedIncomeType(event){
      const value = event.target.value
      const result = JSON.parse(value);
      this.getFeesAndChargesBilling(result.incomeTypeId)
    },
    getIncomeTypes(){
      this.message =null
      this.billingModule.incomeTypes.splice(0)
      this.feesAndCharges.splice(0)
      const data = new FormData();
      data.append("function", "getIncomeTypes");
      data.append("incomeTypePrefix", "PLTRENT");
      data.append("keyword", "Plot Rent");
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.billingModule.incomeTypes =res.data.data.incomeTypes
              this.getFeesAndChargesBilling(this.billingModule.incomeTypes[0].incomeTypeId)
            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    getFeesAndChargesBilling(incomeTypeId){
      this.message =null
      this.feesAndCharges.splice(0)
      const data = new FormData();
      data.append("function", "getFeesAndCharges");
      data.append("incomeTypeId",incomeTypeId);
      execute(data,biller)
          .then((res) =>{
            if (res.data.success) {
              this.feesAndCharges = res.data.data.feesAndCharges
              this.selectedFeeAndChargeFirstOne()
            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    selectedFeeAndCharge(event){
      const data = JSON.parse(event.target.value)
      this.amount = data.unitFeeAmount
      this.feeID = data.feeId
      this.unitFeeAmount = data.unitFeeAmount
      this.feesAndChargeItems = []
      this.feesAndChargeItems.splice(0)
      this.feesAndChargeItems.push(data)

    },
    selectedFeeAndChargeFirstOne(){
      const data = this.feesAndCharges[0]
      this.amount = data.unitFeeAmount
      this.feeID = data.feeId
      this.unitFeeAmount = data.unitFeeAmount
      this.feesAndChargeItems = []
      this.feesAndChargeItems.splice(0)
      this.feesAndChargeItems.push(data)

    },

    selectPlot(plot,title){

      this.billItem.splice(0)
      this.quantity =1;
      this.unitFeeAmount =1;

      this.getIncomeTypes()

      //
      this.title =title
      this.plot = plot
      this.selectedPlots.splice(0)
      this.totalAmount =0
      this.feesAndChargeItems.splice(0)
      this.selectedPlots.push(plot)

    },
    deletePlot(plot) {
      this.plot = plot;

      // Display a confirmation dialog
      if (window.confirm("Are you sure you want to delete the plot '" + this.plot.plotNumber + "'?")) {
        const data = new FormData();
        data.append("function", "deletePlot");
        data.append("id", this.plot.id);
        data.append("sessionNames", sessionStorage.getItem("sessionNames"));
        data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
        execute(data,plotRent)
            .then((res) =>{
              if (res.data.success) {
                this.getPlots()
              }else{
                this.message =res.data.message
              }
            })
            .catch((e) => {
              //alert(e.message);
              this.message =e.message
            });
      } else {
        // If the user cancels, do nothing or perform any other desired action
      }
    },
    getPlots() {
      this.loading = true
      this.plots.splice(0)
      const data = new FormData();
      data.append("function", "getPlots");
      data.append("search", this.keyword !== undefined ? this.keyword : "");
      data.append("page", this.currentPage);
      data.append("rows_per_page", this.pageSize);
      data.append("dateFrom", this.dateFrom);
      data.append("dateTo", this.dateTo);
      data.append("subCountyID", this.subCountyID !== null && this.subCountyID !== undefined ? this.subCountyID : "");
      data.append("wardID", this.wardID !== null && this.wardID !== undefined ? this.wardID : "");
      execute(data, plotRent)
          .then((res) => {
            this.loading = false
            if (res.data.success) {

              this.plots = res.data.data.plots
              this.summary = res.data.data.count.count
              this.totalItems = res.data.data.count.count
              this.totalPages =  Math.ceil(this.totalItems / this.pageSize);


              this.totalAnnual = this.plots.reduce((acc, cur) => {
                let totalAmount = Number(cur.annualCharges);
                return acc + totalAmount;
              }, 0);

              this.totalBalance = this.plots.reduce((acc, cur) => {
                let totalAmount = Number(cur.outstandingArrears);
                return acc + totalAmount;
              }, 0);

              this.totalArrears = this.plots.reduce((acc, cur) => {
                let totalAmount = Number(cur.outstandingArrears);
                return acc + totalAmount;
              }, 0);



            } else {
              this.message = res.data.message
            }
          })
          .catch((e) => {
            this.message = e.message
          });
    },
    addPlotRent() {

      this.plot.subCountyName = this.subCounty.subCountyName
      this.plot.subCountyID = this.subCounty.subCountyID
      this.plot.wardName = this.ward.wardName
      this.plot.wardID = this.ward.wardID
      this.plot.createdBy = sessionStorage.getItem("sessionNames")
      this.plot.createdByIDNo = sessionStorage.getItem("sessionIdNo")
      const data = {
        ...this.plot,
        function: "addPlotRent"
      };

      executeJson(data, plotRent)
          .then((res) => {
            if (res.data.success) {
              alert(res.data.message)
              this.plot = {}
              this.getPlots()
              this.title ="Add New Plot"

            } else {
              this.message = res.data.message
              alert(res.data.message)

            }
          })
          .catch((e) => {
            this.message = e.message
          });
    },
    getRight(type){
      return getRight(type)
    },
    generateFeeAndChargesItem() {
      // Loop through the selectedVehicles array
      for (let item of this.selectedPlots) {
        // Create a new object with the mapped values
        let newItem = {
          feeId: this.feeID, // Map feeID to feeId
          unitFeeAmount: this.amount, // Map amount to unitFeeAmount
          customer: item.plotNumber, // Map numberPlate to customer
        };
        // Push the new object to the feesAndChargeItems array
        this.feesAndChargeItems.splice(0)
        this.feesAndChargeItems.push(newItem);
      }
      this.addToBill()
    },
    addToBill(){
      for (const { typeDescription,feeId, feeDescription, unitFeeAmount, accountNo, costCenterNo, incomeTypeId,customer} of this.feesAndChargeItems) {
        this.totalAmount += parseFloat(unitFeeAmount);
        this.billItem.push({
          typeDescription,
          feeDescription,
          unitFeeAmount: unitFeeAmount,
          accountNo,
          costCenterNo,
          incomeTypeId,
          revenueStreamItem: this.plot.plotNumber,
          feeId,
          amount:  unitFeeAmount,
          customer: `${this.plot.plotNumber}}|${this.plot.ownerNames}|${this.plot.id}`,
          zone: this.plot.zone,
          subCountyID: this.plot.subCountyID,
          subCountyName:this.plot.subCountyName,
          wardID: this.plot.wardID,
          wardName: this.plot.wardName,
          idNo: sessionStorage.getItem("sessionIdNo"),
          phoneNumber: sessionStorage.getItem("sessionPhoneNumber"),
          names: sessionStorage.getItem("sessionNames"),
          customerPhoneNumber: this.plot.phoneNumber,
          description: `UPN NO.: ${this.plot.upn} | Block No. : ${this.plot.block} | Plot No.: ${this.plot.plotNumber} | Address.: ${this.plot.plotAddress} | Name.: ${this.plot.ownerNames} | Ref ID.: ${this.plot.id}`,
        });
      }
    },
    generateDemandBill(){
      this.demandNotice = false
      this.generateBillLoading = true
      this.amount = this.plot.outstandingArrears

      this.generateFeeAndChargesItem()

      this.paymentMethod = false;
      this.paymentPanel = true;
      this.transactionSummary = false ;
      this.transactionCompleted = false
      this.message ="Generating Bill...please wait... "
      const data = new FormData();
      data.append("function", "generateBill3");
      data.append("billItem", JSON.stringify(this.billItem));
      data.append("customerPhoneNumber", this.plot.ownerPhoneNo);
      data.append("messageCode","DEMAND-NOTICE");
      data.append("names",this.plot.ownerNames);
      data.append("email",this.plot.ownerEmail);
      execute(data,biller)
          .then((res) =>{
            this.demandNotice = true
            this.message =res.data.message
            if (res.data.success) {
              this.billDetails = res.data.data.bill.billDetails
              this.billInfo = res.data.data.bill.billInfo
              this.paymentData.amount = res.data.data.bill.billDetails.billBalance
              this.paymentData.accNo = res.data.data.bill.billDetails.billNo
              this.paymentData.payBillNo = res.data.data.billGenerated.payBillNo

              this.paymentData.sessionNames =sessionStorage.getItem("sessionNames")
              this.paymentData.sessionIdNo =sessionStorage.getItem("sessionIdNo")
              this.paymentData.sessionPhoneNumber = sessionStorage.getItem("sessionPhoneNumber")



            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    generateBill(){
      this.generateFeeAndChargesItem()

      this.paymentMethod = false;
      this.paymentPanel = true;
      this.transactionSummary = false ;
      this.transactionCompleted = false
      this.message ="Generating Bill...please wait... "
      const data = new FormData();
      data.append("function", "generateBill3");
      data.append("billItem", JSON.stringify(this.billItem));
      execute(data,biller)
          .then((res) =>{
            this.message =res.data.message
            if (res.data.success) {
              this.billDetails = res.data.data.bill.billDetails
              this.billInfo = res.data.data.bill.billInfo
              this.paymentData.amount = res.data.data.bill.billDetails.billBalance
              this.paymentData.accNo = res.data.data.bill.billDetails.billNo
              this.paymentData.payBillNo = res.data.data.billGenerated.payBillNo

              this.paymentData.sessionNames =sessionStorage.getItem("sessionNames")
              this.paymentData.sessionIdNo =sessionStorage.getItem("sessionIdNo")
              this.paymentData.sessionPhoneNumber = sessionStorage.getItem("sessionPhoneNumber")



            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            this.message =e.message
          });
    },
    printBill() {
      localStorage['params'] = JSON.stringify({
        billNo: this.billDetails.billNo
      })
      const routeData = this.$router.resolve({name: 'bill'});
      window.open(routeData.href, '_blank');
    },
    printBillDemandNotice() {
      localStorage['params'] = JSON.stringify({
        billNo: this.billDetails.billNo
      })
      const routeData = this.$router.resolve({name: 'demand-notice'});
      window.open(routeData.href, '_blank');
    },
    printReceipt() {
      if (this.receiptDetails.receiptNo == null) {
        alert("Try again later");
      } else {
        //  this.$refs.closeReceived.click();
        localStorage['params'] = JSON.stringify({
          receiptNo: this.receiptDetails.receiptNo
        })
        const routeData = this.$router.resolve({name: 'receipt'});
        window.open(routeData.href, '_blank');
      }
    },
    showPaymentMethod(){
      this.paymentMethod = true;
      this.paymentPanel = false;
      this.transactionSummary = false;
      this.transactionCompleted = false
    },
    showTransactionSummary(paymentMode){

      this.paymentData.paymentMode = paymentMode

      if(paymentMode === "MPESA") {
        if (this.phoneNumber == null) {
          alert("Enter phone number");
          return;
        }
      }
      if(paymentMode === "BANK") {
        if (this.paymentData.bankReceiptNo == null) {
          alert("Enter Bank Receipt No");
          return;
        }
      }

      this.paymentMethod = false;
      this.paymentPanel = false;
      this.transactionSummary = true;
      this.transactionCompleted = false
    },
    makePayment() {
      if(this.paymentData.paymentMode === "MPESA") {
        if (this.phoneNumber == null) {
          alert("Enter phone number");
          return;
        }
      }
      if(this.paymentData.paymentMode === "BANK") {
        if (this.paymentData.bankReceiptNo == null) {
          alert("Enter Bank Receipt No");
          return;
        }
      }
      this.message = "Payment Request sent";
      this.paymentData.phoneNumber = this.phoneNumber

      const data = new FormData();
      data.append("function", "makePayment");
      data.append("paymentData", JSON.stringify(this.paymentData));


      execute(data, biller)
          .then((res) => {
            if (res.data.success) {
              this.paymentMethod = false;
              this.paymentPanel = false;
              //start checking payment
              this.timer = setInterval(() => {

                this.checkPayment();
              }, 3000);
            } else {
              this.rate = null;
              alert(res.data.message);
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },
    checkPayment() {
      this.randomNumber = Math.floor(Math.random() * 3) + 1
      if(this.randomNumber === 1){
        this.message = "Waiting for payment.";
      }
      if(this.randomNumber === 2){
        this.message = "Waiting for payment..";
      }
      if(this.randomNumber ===3){
        this.message = "Waiting for payment...";
      }
      const data = new FormData();
      data.append("function", "checkPayment");
      data.append("accountRef", this.paymentData.accNo);

      execute(data, biller)
          .then((res) => {
            if (res.data.success) {
              this.message = null;
              clearInterval(this.timer);
              this.receipt = true;
              this.paymentData.success = false;
              this.transaction = res.data.data.transaction;
              this.receiptDetails = res.data.data.receipt.receiptDetails
              //Close the modal
              this.transactionSummary = false
              this.transactionCompleted = true
            }
          })
          .catch((e) => {
            alert(e.message);
          });
    },
    formatPrice(value) {
      var formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'KES',
        minimumFractionDigits: 2
      });
      return formatter.format(value);
    },
    gotTo(route,plotNumber,zone){
      sessionStorage.setItem("plotNumber",plotNumber)
      sessionStorage.setItem("zone",zone)
      router.push(route)
    },
    getZones(subCountyID){
      this.message = null
      this.zones.splice(0)
      const data = new FormData();
      data.append("function", "getZones");
      data.append("subCountyID", subCountyID);
      execute(data,authUrl)
          .then((res) =>{
            if (res.data.success) {
              this.zones = res.data.data.zones
              this.zones.forEach((value, index) => {
                if (this.selectedUser.zone.includes(value.zone)) {
                  this.zone.push(this.zones[index].zone)

                }
              });

            }else{
              this.message =res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message =e.message
          });
    },
    handleFileChange: function (event) {
      console.log("File selected:", event.target.files[0]);
      this.selectedFile = event.target.files[0];
    },
    uploadPlotRentToQue() {

      if (!this.zone || this.zone.trim() === '' || !this.selectedFile) {
        // Handle the case when this.zone is empty or null, or when this.selectedFile is not set
        alert("Either zone is empty or null, or selectedFile is not set.")
      } else {

        this.closeModal()
        console.log("Upload button clicked");
        console.log("Selected File in uploadExcelToQue:", this.selectedFile);
        this.loading = true
        // Make sure 'file' is a valid File object
        const data = new FormData();
        data.append("function", "uploadPlotRent");
        data.append("fileToUpload", this.selectedFile);
        data.append("zone", this.zone);
        data.append("createdBy", sessionStorage.getItem("sessionNames"));
        data.append("createdByIDNo", sessionStorage.getItem("sessionIdNo"));

        // Assuming 'pay' is defined
        execute2(data,plotRent)
            .then((res) => {
              this.loading = false
              alert(res.data. message)
              if (res.data.success) {
                this.alert.success = true
                this.getPlots();

              } else {
                // Handle failure case
                this.alert.failed = true
                this.message =res.data. message
              }
            })
            .catch((e) => {
              // Handle network or other errors
              // alert(e.message);
              // this.alert.failed = true
            });
      }



    },
    closeModal() {
      var modal = document.querySelector('.modal');
      var modalBackdrop = document.querySelector('.modal-backdrop');

      if (modal && modalBackdrop) {
        modal.style.display = 'none';
        modalBackdrop.style.display = 'none';
      }
    },
    getBill() {
      this.message = null
      this.loading = true
      this.invoiceDetails = false
      const data = new FormData();
      data.append("function", "getBill");
      data.append("billNo", this.billNo);
      execute(data, biller)
          .then((res) => {
            this.loading = false
            if (res.data.success) {

              this.billInfo = res.data.data.billInfo
              this.billDetail = res.data.data.billDetails
              if(this.billDetail.status ==='PAID'){
                this.invoiceDetails = true
              }else {
                this.message = "The bill is "+ this.billDetail.status+ `. Amount: KES ${this.billDetail.detailAmount} but the customer paid KES ${this.billDetail.receiptAmount} Customer to pay the balance of KES ${this.billDetail.billBalance}`
              }
            } else {
              this.message = res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message = e.message
          });
    },
    captureOwnerDetails(){
      this.captureOwner = true
    },
    transferRequest(){
      this.loading = true
      const data = new FormData();
      data.append("function", "transferRequest");
      data.append("billNo", this.billNo);
      data.append("ownerName", this.ownerName);
      data.append("ownerID", this.ownerID);
      data.append("ownerPhone", this.ownerPhone);
      data.append("ownerEmail", this.ownerEmail);
      data.append("minutesNumber", this.minuteNumber);
      data.append("fileToUpload", this.selectedFile);
      data.append("plotNumber", this.plot.plotNumber);
      data.append("id", this.plot.id);
      data.append("sessionNames", sessionStorage.getItem('sessionNames'));
      data.append("sessionIdNo", sessionStorage.getItem('sessionIdNo'));
      data.append("sessionPhoneNumber", sessionStorage.getItem('sessionPhoneNumber'));
      data.append("propertyType","PLOT");

      execute(data, plotRent)
          .then((res) => {
            this.loading = false
            if (res.data.success) {
              this.captureOwner = false
              this.transferSuccess = true

            } else {
              this.message = res.data.message
            }
          })
          .catch((e) => {
            //alert(e.message);
            this.message = e.message
          });
    }

  }

}
</script>

<style scoped>

</style>