import {createWebHashHistory, createRouter} from "vue-router";
import Login from "@/components/Login.vue";
import Dashboard from "@/components/Dashboard.vue";
import Dashboard2 from "@/components/Dashboard2.vue";
import NewBill from "@/components/NewBill.vue";
import Bill from "../../public/PrintDocs/Bill/Bill.vue";
import DemandNotice from "../../public/PrintDocs/notice/DemandNotice.vue";
import PrintReceipt from "../../public/PrintDocs/Receipts/PrintReceipt.vue";
import BillsRaised from "@/components/BillsRaised";
import BillsReceipted from "@/components/BillsReceipted";
import UnPaidBills from "@/components/UnPaidBills";
import ReceiptedPayments from "@/components/ReceiptedPayments";
import ReceiptsNew from "@/components/ReceiptsNew";
import TradePermts from "@/components/TradePermts";
import TradePermit from "../../public/PrintDocs/Trade-Permit/TradePermit";
import Customers from "@/components/Customers";
import AddUser from "@/components/systemusers/AddUser";
import Users from "@/components/systemusers/Users";
import Transactions from "@/components/Transactions";
import Zones from "@/components/systemusers/Zones";
import FinanceAct from "@/components/finance-act/FinanceAct";
import Department from "@/components/systemsettings/Department";
import RevenueStream from "@/components/systemsettings/RevenueStream";
import SubCountyReport from "../../public/PrintDocs/Print-Reports/SubCountyReport";
import Rentals from "@/components/rent/Rentals";
import Stalls from "@/components/rent/Stalls";
import AgentsTransactions from "@/components/Transactions/AgentsTransactions";
import UserLogs from "@/components/systemusers/UserLogs";
import AllUserLogs from "@/components/systemusers/AllUserLogs";
import UserCollections from "@/components/systemusers/UserCollections";
import ReportsSubCounty from "@/components/reports/collectionbysubcounties/ReportsSubCounty";
import ReportsSubCountyDetails from "@/components/reports/collectionbysubcounties/AllTransactions";
import ReportsSubCountyStreams from "@/components/reports/collectionbysubcounties/ReportsSubCountyStreams";
import ReportsSubCountyStreamsBreakdown from "@/components/reports/collectionbysubcounties/ReportsSubCountyStreamsBreakdown";
import ReportsSubCountyStreamsDetails from "@/components/reports/collectionbysubcounties/ReportsSubCountyStreamsDetails";
import LiveMonitoring from "@/components/livemonitoring/LiveMonitoring";
import ReportsSubCountyZones from "@/components/reports/collectionbysubcounties/ReportsSubCountyZones";
import ReportsSubCountyZonesDetails from "@/components/reports/collectionbysubcounties/ReportsSubCountyZonesDetails";
import ReportsSubCountyAgents from "@/components/reports/collectionbysubcounties/ReportsSubCountyAgents";
import ReportsSubCountyAgentDetails from "@/components/reports/collectionbysubcounties/ReportsSubCountyAgentDetails";
import Mapping from "@/components/Mapping";
import CertBizStats from "@/components/sbp/CertBizStats";
import PendingValidation from "@/components/sbp/PendingValidation";
import ReportsAgents from "@/components/reports/agents/ReportsAgents";
import ReportsAgentsDetails from "@/components/reports/agents/ReportsAgentsDetails";
import TargetsSubCounty from "@/components/systemsettings/TargetsSubCounty";
import PendingInspection from "@/components/sbp/PendingInspection";
import PendingApproval from "@/components/sbp/PendingApproval";
import Declined from "@/components/sbp/Declined";
import Approved from "@/components/sbp/Approved";
import PaidSBP from "@/components/sbp/PaidSBP";
import UnPaidSBP from "@/components/sbp/UnPaidSBP";
import SbpUnPaidBills from "@/components/sbp/SbpUnPaidBills";
import SbpPaidBills from "@/components/sbp/SbpPaidBills";
import SBPMap from "@/components/sbp/SBPMap";
import TargetsCollectors from "@/components/systemsettings/TargetsCollectors";
import TargetStream from "@/components/systemsettings/TargetStream";
import ReportsSubCountyTeam from "@/components/reports/collectionbysubcounties/ReportsSubCountyTeam";
import ReportsSubCountyTeamDetails from "@/components/reports/collectionbysubcounties/ReportsSubCountyTeamDetails";
import BusinessData from "@/components/sbp/data/BusinessData";
import BusinessMapping from "@/components/sbp/data/BusinessMapping";
import ParkingFleet from "@/components/parking/ParkingFleet";
import ParkingFleetNewSacco from "@/components/parking/ParkingFleetNewSacco";
import ParkingFleetPayment from "@/components/parking/ParkingFleetPayment";
import ParkingSticker from "../../public/PrintDocs/Parking-Sticker/ParkingSticker";
import ReportsStreams from "@/components/reports/streams/ReportsStreams";
import ReportsStreamsSubcounties from "@/components/reports/streams/ReportsStreamsSubcounties"
import ReportsStreamsWards from "@/components/reports/streams/ReportsStreamsWards"
import ReportsStreamsZones from "@/components/reports/streams/ReportsStreamsZones"
import SetAccounts from "@/components/systemusers/SetAccounts";
import ReportsSubCountyRevenueStreams from "@/components/reports/collectionbysubcounties/ReportsSubCountyRevenueStreams";
import ParkingFleetDetails from "@/components/parking/ParkingFleetDetails";
import DailyParking from "@/components/parking/DailyParking";
import MonthlyParking from "@/components/parking/MonthlyParking";
import WeeklyParking from "@/components/parking/WeeklyParking";
import BusParkParking from "@/components/parking/BusParkParking";
import VehicleRegister from "@/components/parking/VehicleRegister";
import ClampedVehicles from "@/components/parking/ClampedVehicles";
import ParkingDashboard from "@/components/parking/ParkingDashboard";
import ParkingFleetDetailsStatements from "@/components/parking/ParkingFleetDetailsStatements";
import ParkingFleetDetailsHistory from "@/components/parking/ParkingFleetDetailsHistory";
import ActiveUsers from "@/components/livemonitoring/ActiveUsers";
import InActiveUsers from "@/components/livemonitoring/InActiveUsers";
import DormantUsers from "@/components/livemonitoring/DormantUsers";
import NeverLoggedIn from "@/components/livemonitoring/NeverLoggedIn";
import Recon from "@/components/recon/Recon";
import ReconDetails from "@/components/recon/ReconDetails.vue";
import AllReconDetails from "@/components/recon/AllReconDetails.vue";
import FlaggedTransactionCorrection from "@/components/actioncenter/FlaggedTransactionCorrection";
import TransactionCorrectionRequest from "@/components/actioncenter/TransactionCorrectionRequest";
import otp from "@/components/otp.vue";
import PlotRent from "@/components/plotrent/PlotRent";
import FireDashboard from "@/components/fire/FireDashboard.vue";
import CertFirePending from "@/components/fire/CertFirePending.vue";
import PendingApprovalFire from "@/components/fire/PendingApprovalFire.vue";
import ActiveFireCert from "@/components/fire/ActiveFireCert.vue";
import FirePermit from "../../public/PrintDocs/FireCertificate/FirePermit.vue";
import PlotStatements from "@/components/plotrent/PlotStatements.vue";
import LandRate from "@/components/plotrent/LandRate.vue";
import PendingTransfers from "@/components/plotrent/PendingTransfers";
import DeclinedTransfers from "@/components/plotrent/DeclinedTransfers";
import ReApproveTransfers from "@/components/plotrent/ReApproveTransfers";
import ApprovedTransfers from "@/components/plotrent/ApprovedTransfers";
import HousingRegister from "@/components/housing/HousingRegister.vue";
import HousingNew from "@/components/housing/HousingNew.vue";
import HousingNewUnit from "@/components/housing/HousingNewUnit.vue";
import ExpiredFireCert from "@/components/fire/ExpiredFireCert.vue";
import HousingDetail from "@/components/housing/HousingDetail.vue";
import FirePaidBills from "@/components/fire/FirePaidBills.vue";
import CertAlcoholOngoing from "@/components/liquor/CertAlcoholOngoing.vue";
import CertAlcoholPendingInspection from "@/components/liquor/CertAlcoholPendingInspection.vue";
import CertAlcoholPendingReview from "@/components/liquor/CertAlcoholPendingReview.vue";
import CertAlcoholApproved from "@/components/liquor/CertAlcoholApproved.vue";
import CertAlcoholActive from "@/components/liquor/CertAlcoholActive.vue";
import LiquorPermit from "../../public/PrintDocs/Alcohol/LiquorPermit.vue";
import CertAlcoholDashboard from "@/components/liquor/CertAlcoholDashboard.vue";
import HygieneDashboard from "@/components/hygiene/HygieneDashboard.vue";
import CertHygienePending from "@/components/hygiene/CertHygienePending.vue";
import PendingApprovalHygiene from "@/components/hygiene/PendingApprovalHygiene.vue";
import ActiveHygieneCert from "@/components/hygiene/ActiveHygieneCert.vue";
import ExpiredHygieneCert from "@/components/hygiene/ExpiredHygieneCert.vue";
import HygienePaidBills from "@/components/hygiene/HygienePaidBills.vue";
import HealthPermit from "../../public/PrintDocs/hygene/HealthPermit.vue";
import MedicalDashboard from "@/components/medical/MedicalDashboard.vue";
import CertMedicalPending from "@/components/medical/CertMedicalPending.vue";
import PendingApprovalMedical from "@/components/medical/PendingApprovalMedical.vue";
import ActiveMedicalCert from "@/components/medical/ActiveMedicalCert.vue";
import ExpiredMedicalCert from "@/components/medical/ExpiredMedicalCert.vue";
import MedicalPaidBills from "@/components/medical/MedicalPaidBills.vue";
import MedicalCertificate from "../../public/PrintDocs/healthCertificates/MedicalCertificate.vue";
import ReportsSources from "@/components/reports/sources/ReportsSources.vue";
import WaivedBills from "@/components/WaivedBills.vue";
import ReportsStreamsUnpaid from "@/components/reports/unpaid/ReportsStreamsUnpaid.vue";
import UnPaidBillsStreams from "@/components/reports/unpaid/UnPaidBillsStreams.vue";

import ReportsStreamsPaid from "@/components/reports/paid/ReportsStreamsPaid.vue";
import PaidBillsStreams from "@/components/reports/paid/PaidBillsStreams.vue";
import ChangePassword from "@/components/ChangePassword.vue";

import {biller, execute} from "@/api";


const routes =[

    {
        path:"/change-password",
        name:"change-password",
        component: ChangePassword,
        meta:{public: false}
    },

    {
        path:"/paid-bills-streams",
        name:"paid-bills-streams",
        component: PaidBillsStreams,
        meta:{public: false}
    },

    {
        path:"/reports-streams-paid",
        name:"reports-streams-paid",
        component: ReportsStreamsPaid,
        meta:{public: false}
    },

    {
        path:"/un-paid-bills-streams",
        name:"un-paid-bills-streams",
        component: UnPaidBillsStreams,
        meta:{public: false}
    },

    {
        path:"/reports-streams-unpaid",
        name:"reports-streams-unpaid",
        component: ReportsStreamsUnpaid,
        meta:{public: false}
    },
    {
        path:"/waived-bills",
        name:"waived-bills",
        component: WaivedBills,
        meta:{public: false}
    },
    {
        path:"/reports-sources",
        name:"reports-sources",
        component: ReportsSources,
        meta:{public: false}
    },
    {
        path:"/medical-certificate",
        name:"medical-certificate",
        component: MedicalCertificate,
        meta:{public: false}
    },
    {
        path:"/medical-paid-bills",
        name:"medical-paid-bills",
        component: MedicalPaidBills,
        meta:{public: false}
    },
    {
        path:"/expired-medical-cert",
        name:"expired-medical-cert",
        component: ExpiredMedicalCert,
        meta:{public: false}
    },
    {
        path:"/active-medical-cert",
        name:"active-medical-cert",
        component: ActiveMedicalCert,
        meta:{public: false}
    },
    {
        path:"/pending-approval-medical",
        name:"pending-approval-medical",
        component: PendingApprovalMedical,
        meta:{public: false}
    },
    {
        path:"/cert-medical-pending",
        name:"cert-medical-pending",
        component: CertMedicalPending,
        meta:{public: false}
    },
    {
        path:"/medical-dashboard",
        name:"medical-dashboard",
        component: MedicalDashboard,
        meta:{public: false}
    },
    {
        path:"/health-permit",
        name:"health-permit",
        component: HealthPermit,
        meta:{public: true}
    },
    {
        path:"/hygiene-paid-bills",
        name:"hygiene-paid-bills",
        component: HygienePaidBills,
        meta:{public: false}
    },
    {
        path:"/expired-hygieneCert",
        name:"expired-hygieneCert",
        component: ExpiredHygieneCert,
        meta:{public: false}
    },
    {
        path:"/active-hygieneCert",
        name:"active-hygieneCert",
        component: ActiveHygieneCert,
        meta:{public: false}
    },
    {
        path:"/pending-approval-hygiene",
        name:"pending-approval-hygiene",
        component: PendingApprovalHygiene,
        meta:{public: false,}
    },
    {
        path:"/cert-hygiene-pending",
        name:"cert-hygiene-pending",
        component: CertHygienePending,
        meta:{public: false,}
    },
    {
        path:"/hygiene-dashboard",
        name:"hygiene-dashboard",
        component: HygieneDashboard,
        meta:{public: false}
    },
    {
        path:"/cert-alcohol-dashboard",
        name:"cert-alcohol-dashboard",
        component: CertAlcoholDashboard,
        meta:{public: false}
    },
    {
        path:"/cert-alcohol-active",
        name:"cert-alcohol-active",
        component: CertAlcoholActive,
        meta:{public: false}
    },

    {
        path:"/liquor-permit",
        name:"liquor-permit",
        component: LiquorPermit,
        meta:{public: true}
    },
    {
        path:"/cert-alcohol-approved",
        name:"cert-alcohol-approved",
        component: CertAlcoholApproved,
        meta:{public: false}
    },
    {
        path:"/cert-alcohol-review",
        name:"cert-alcohol-review",
        component: CertAlcoholPendingReview,
        meta:{public: false}
    },
    {
        path:"/cert-alcohol-inspection",
        name:"cert-alcohol-inspection",
        component: CertAlcoholPendingInspection,
        meta:{public: false}
    },
    {
        path:"/cert-alcohol-ongoing",
        name:"cert-alcohol-ongoing",
        component: CertAlcoholOngoing,
        meta:{public: false}
    },
    {
        path:"/housing-detail",
        name:"housing-detail",
        component: HousingDetail,
        meta:{public: false}
    },
    {
        path:"/fire-paid-bills",
        name:"fire-paid-bills",
        component: FirePaidBills,
        meta:{public: false}
    },
    {
        path:"/expired-fireCert",
        name:"expired-fireCert",
        component: ExpiredFireCert,
        meta:{public: false}
    },
    {
        path:"/housing-new",
        name:"housing-new",
        component: HousingNew,
        meta:{public: false}
    },
    {
        path:"/housing-new-unit",
        name:"housing-new-unit",
        component: HousingNewUnit,
        meta:{public: false}
    },
    {
        path:"/housing-register",
        name:"housing-register",
        component: HousingRegister,
        meta:{public: false}
    },
    {
        path:"/plot-statements",
        name:"plot-statements",
        component: PlotStatements,
        meta:{public: false}
    },{
        path:"/land-rate",
        name:"land-rate",
        component: LandRate,
        meta:{public: false}
    },
    {
        path:"/pending-transfer",
        name:"pending-transfer",
        component: PendingTransfers,
        meta:{public: false}
    },
    {
        path:"/decline-transfer",
        name:"decline-transfer",
        component: DeclinedTransfers,
        meta:{public: false}
    },
    {
        path:"/re-approve-transfer",
        name:"re-approve-transfer",
        component: ReApproveTransfers,
        meta:{public: false}
    },
    {
        path:"/approved-transfer",
        name:"approved-transfer",
        component: ApprovedTransfers,
        meta:{public: false}
    },
    {
        path:"/demand-notice",
        name:"demand-notice",
        component: DemandNotice,
        meta:{public: true}
    },
    {
        path:"/fire-permit",
        name:"fire-permit",
        component: FirePermit,
        meta:{public: true}
    },
    {
        path:"/active-fireCert",
        name:"active-fireCert",
        component: ActiveFireCert,
        meta:{public: false}
    },
    {
        path:"/pending-approval-fire",
        name:"pending-approval-fire",
        component: PendingApprovalFire,
        meta:{public: false,}
    },
    {
        path:"/cert-fire-pending",
        name:"cert-fire-pending",
        component: CertFirePending,
        meta:{public: false,}
    },
    {
        path:"/fire-dashboard",
        name:"fire-dashboard",
        component: FireDashboard,
        meta:{public: false,}
    },
    {
        path:"/plot_rent",
        name:"plot_rent",
        component: PlotRent,
        meta:{public: false,}
    },

    {
        path:"/flagged-transaction-correction",
        name:"flagged-transaction-correction",
        component: FlaggedTransactionCorrection,
        meta:{public: false,}
    },
    {
        path:"/transaction-correction-request",
        name:"transaction-correction-request",
        component: TransactionCorrectionRequest,
        meta:{public: false,}
    },
    {
        path:"/otp",
        name:"otp",
        component: otp,
        meta:{public: false,}
    },
    {
        path:"/recon",
        name:"recon",
        component: Recon,
        meta:{public: false,}
    },
     {
        path:"/recon-details",
        name:"recon-details",
        component: ReconDetails,
        meta:{public: false,}
    },
    {
        path:"/recon-all",
        name:"recon-all",
        component: AllReconDetails,
        meta:{public: false,}
    },
    {
        path:"/parking-fleet-details-history",
        name:"parking-fleet-details-history",
        component: ParkingFleetDetailsHistory,
        meta:{public: false,}
    },
    {
        path:"/parking-fleet-details-statements",
        name:"parking-fleet-details-statements",
        component: ParkingFleetDetailsStatements,
        meta:{public: false,}
    },
    {
        path:"/clamped_vehicles",
        name:"clamped_vehicles",
        component: ClampedVehicles,
        meta:{public: false,}
    },
    {
        path:"/parking_dashboard",
        name:"parking_dashboard",
        component: ParkingDashboard,
        meta:{public: false,}
    },{
        path:"/vehicles_register",
        name:"vehicles_register",
        component: VehicleRegister,
        meta:{public: false,}
    },{
        path:"/bus_park",
        name:"bus_park",
        component: BusParkParking,
        meta:{public: false,}
    },{
        path:"/monthly_parking",
        name:"monthly_parking",
        component: MonthlyParking,
        meta:{public: false,}
    },
    {
        path:"/weekly_parking",
        name:"weekly_parking",
        component: WeeklyParking,
        meta:{public: false,}
    },
    {
        path:"/daily_parking",
        name:"daily_parking",
        component: DailyParking,
        meta:{public: false,}
    },
    {
        path:"/parking-fleet-details",
        name:"parking-fleet-details",
        component: ParkingFleetDetails,
        meta:{public: false,
        }
    },
    {
        path:"/reports-streams",
        name:"reports-streams",
        component: ReportsStreams,
        meta:{public: false,
        }
    },
    {
        path:"/reports-sub-county-revenue-streams",
        name:"reports-sub-county-revenue-streams",
        component: ReportsSubCountyRevenueStreams,
        meta:{public: false,
        }
    },
    {
        path:"/set-accounts",
        name:"set-accounts",
        component: SetAccounts,
        meta:{public: false,
        }
    },
    {
        path:"/reports-streams-sub-counties",
        name:"reports-streams-sub-counties",
        component: ReportsStreamsSubcounties,
        meta:{public: false}
    },
    {
        path:"/reports-streams-wards",
        name:"reports-streams-wards",
        component: ReportsStreamsWards,
        meta:{public: false}
    },
    {
        path:"/reports-streams-zones",
        name:"reports-streams-zones",
        component: ReportsStreamsZones,
        meta:{public: false}
    },
    {
        path:"/reports-agents",
        name:"reports-agents",
        component: ReportsAgents,
        meta:{public: false,
        }
    },
    {
        path:"/targets-SubCounty",
        name:"targets-SubCounty",
        component: TargetsSubCounty,
        meta:{public: false}
    },
    {
        path:"/parking-sticker",
        name:"parking-sticker",
        component: ParkingSticker,
        meta:{public: false}
    }
    ,
    {
        path:"/reports-agents-details",
        name:"reports-agents-details",
        component: ReportsAgentsDetails,
        meta:{public: false}
    },

    {
        path:"/",
        name:"login",
        component: Login,
        meta:{public: true}
    },
    {
        path:"/dashboard",
        name:"dashboard",
        component: Dashboard,
        meta:{public: false}
    },
    {
        path:"/dashboard2",
        name:"dashboard2",
        component: Dashboard2,
        meta:{public: false}
    },
    {
        path:"/new-bill",
        name:"newBill",
        component: NewBill,
        meta:{public: false}
    },

    {
        path: '/bill',
        name: 'bill',
        component: Bill,
        meta:{public: false}
    },
    {
        path: "/receipt",
        name: "receipt",
        component: PrintReceipt,
        meta: {public: true}
    },
    {
        path: "/bills-raised",
        name: "bills-raised",
        component: BillsRaised,
        meta: {public: false}
    },
    {
        path: "/un-paid-bills",
        name: "un-paid-bills",
        component: UnPaidBills,
        meta: {public: false}
    },
    {
        path: "/bills-receipted",
        name: "bills-receipted",
        component: BillsReceipted,
        meta: {public: false}
    },
    {
        path: "/receipts",
        name: "receipts",
        component: ReceiptedPayments,
        meta: {public: false}
    },
    {
        path: "/receipts-new",
        name: "receipts-new",
        component: ReceiptsNew,
        meta: {public: false}
    },
    {
        path: "/permits",
        name: "permits",
        component: TradePermts,
        meta: {public: false}
    },{
        path: "/trade",
        name: "trade",
        component: TradePermit,
        meta: {public: true}
    },
    {
        path: "/customers",
        name: "customers",
        component: Customers,
        meta: {public: false}
    },
    {
        path: "/add-users",
        name: "add-users",
        component: AddUser,
        meta: {public: false}
    },
    {
        path: "/users",
        name: "users",
        component: Users,
        meta: {public: false}
    },{
        path: "/transactions",
        name: "transactions",
        component: Transactions,
        meta: {public: false}
    },{
        path: "/zones",
        name: "zones",
        component: Zones,
        meta: {public: false}
    },
    {
        path: "/finance-act",
        name: "finance-act",
        component: FinanceAct,
        meta: {public: false}
    },
    {
        path: "/department",
        name: "department",
        component: Department,
        meta: {public: false}
    }, {
        path: "/revenue-stream",
        name: "revenue-stream",
        component: RevenueStream,
        meta: {public: false}
    },{
        path: "/sub-county-report",
        name: "sub-county-report",
        component: SubCountyReport,
        meta: {public: false}
    },
    {
        path: "/rentals",
        name: "rentals",
        component: Rentals,
        meta: {public: false}
    },
    {
        path: "/stalls",
        name: "stalls",
        component: Stalls,
        meta: {public: false}
    },{
        path: "/agent-transactions",
        name: "agent-transactions",
        component: AgentsTransactions,
        meta: {public: false}
    },
    {
        path: "/user-logs",
        name: "user-logs",
        component: UserLogs,
        meta: {public: false}
    },
    {
        path: "/all-user-logs",
        name: "all-user-logs",
        component: AllUserLogs,
        meta: {public: false}
    },

    {
        path: "/user-collections",
        name: "user-collections",
        component: UserCollections,
        meta: {public: false}
    },{
        path: "/reports-sub-county",
        name: "reports-sub-county",
        component: ReportsSubCounty,
        meta: {public: false}
    },
    {
        path: "/reports-sub-counties-details",
        name: "reports-sub-counties-details",
        component: ReportsSubCountyDetails,
        meta: {public: false}
    },{
        path: "/reports-sub-county-streams",
        name: "reports-sub-county-streams",
        component: ReportsSubCountyStreams,
        meta: {public: false}
    },
    {
        path: "/reports-sub-county-streams-break-down",
        name: "reports-sub-county-streams-break-down",
        component: ReportsSubCountyStreamsBreakdown,
        meta: {public: false}
    },
    {
        path: "/reports-sub-county-streams-details",
        name: "reports-sub-county-streams-details",
        component: ReportsSubCountyStreamsDetails,
        meta: {public: false}
    },{
        path: "/live_monitoring",
        name: "live_monitoring",
        component: LiveMonitoring,
        meta: {public: false}
    },
    {
        path: "/active_users",
        name: "active_users",
        component: ActiveUsers,
        meta: {public: false}
    },
    {
        path: "/offline_users",
        name: "offline_users",
        component: InActiveUsers,
        meta: {public: false}
    },
    {
        path: "/dormant_users",
        name: "dormant_users",
        component: DormantUsers,
        meta: {public: false}
    },
    {
        path: "/never_logged_in",
        name: "never_logged_in",
        component: NeverLoggedIn,
        meta: {public: false}
    },
    {
        path: "/reports-sub-county-zones",
        name: "reports-sub-county-zones",
        component: ReportsSubCountyZones,
        meta: {public: false}
    },{
        path: "/reports-sub-county-zones-details",
        name: "reports-sub-county-zones-details",
        component: ReportsSubCountyZonesDetails,
        meta: {public: false}
    },
    {
        path: "/reports-sub-county-agents",
        name: "reports-sub-county-agents",
        component: ReportsSubCountyAgents,
        meta: {public: false}
    },
    {
        path: "/reports-sub-county-agent-details",
        name: "reports-sub-county-agent-details",
        component: ReportsSubCountyAgentDetails,
        meta: {public: false}
    },
    {
        path: "/mapping",
        name: "mapping",
        component: Mapping,
        meta: {public: false}
    },
    {
        path: "/cert-biz-stats",
        name: "cert-biz-stats",
        component: CertBizStats,
        meta: {public: false}
    },
    {
        path: "/pending-validation",
        name: "pending-validation",
        component: PendingValidation,
        meta: {public: false}
    },
    {
        path: "/pending-inspection",
        name: "pending-inspection",
        component: PendingInspection,
        meta: {public: false}
    },
    {
        path: "/pending-approval",
        name: "pending-approval",
        component: PendingApproval,
        meta: {public: false}
    },{
        path: "/declined",
        name: "declined",
        component: Declined,
        meta: {public: false}
    },{
        path: "/approved",
        name: "approved",
        component: Approved,
        meta: {public: false}
    },{
        path: "/paid-sbp",
        name: "paid-sbp",
        component: PaidSBP,
        meta: {public: false}
    },{
        path: "/unpaid-sbp",
        name: "unpaid-sbp",
        component: UnPaidSBP,
        meta: {public: false}
    },{
        path: "/sbp-unpaid-bills",
        name: "sbp-unpaid-bills",
        component: SbpUnPaidBills,
        meta: {public: false}
    },{
        path: "/sbp-paid-bills",
        name: "sbp-paid-bills",
        component: SbpPaidBills,
        meta: {public: false}
    },{
        path: "/sbp-map",
        name: "sbp-map",
        component: SBPMap,
        meta: {public: false}
    },
    {
        path: "/targets-collectors",
        name: "targets-collectors",
        component: TargetsCollectors,
        meta: {public: false}

    }, {
        path: "/targets-stream",
        name: "targets-stream",
        component: TargetStream,
        meta: {public: false}
    },
    {
        path: "/reports-sub-county-team",
        name: "reports-sub-county-team",
        component: ReportsSubCountyTeam,
        meta: {public: false}
    }, {
        path: "/reports-sub-county-team-details",
        name: "reports-sub-county-team-details",
        component: ReportsSubCountyTeamDetails,
        meta: {public: false}
    },
    {
        path: "/business-data",
        name: "business-data",
        component: BusinessData,
        meta: {public: false}
    },{
        path: "/business-mapping",
        name: "business-mapping",
        component: BusinessMapping,
        meta: {public: false}
    },{
        path: "/parking-fleet",
        name: "parking-fleet",
        component: ParkingFleet,
        meta: {public: false}
    },
    {
        path: "/parking-fleet-new",
        name: "parking-fleet-new",
        component: ParkingFleetNewSacco,
        meta: {public: false}
    }, {
        path: "/parking-fleet-payment",
        name: "parking-fleet-payment",
        component: ParkingFleetPayment,
        meta: {public: false}
    }
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
});


let userActivityTimer;

router.beforeEach((to, from, next) => {
    // Reset the user activity timer on each navigation
    clearTimeout(userActivityTimer);

    // Check if the target route requires authentication
    if (!to.meta.public && !to.hash.includes('/trade/')) {
        // Check if the user is logged in or not
        if (sessionStorage.getItem("isLoggedIn")) {
            // If yes, allow the navigation
            next();

            // Set up the user activity timer
            userActivityTimer = setTimeout(() => {
                // Log out the user after 5 minutes of inactivity
                sessionStorage.removeItem("isLoggedIn");
                next("/");
            }, 5 * 60 * 1000); // 5 minutes in milliseconds
        } else {
            // If no, redirect the user to the login page
            next("/");
        }
    } else {
        // If the target route does not require authentication or has parameters, allow the navigation
        next();
    }
});

// Optionally, you may want to handle user activity within the application
// to reset the timer. Here's an example assuming you have a user activity event.

// Example: Reset timer on user activity
function handleUserActivity() {
    clearTimeout(userActivityTimer);
    userActivityTimer = setTimeout(() => {
        // Log out the user after 5 minutes of inactivity

        const data = new FormData();
        data.append("function", "timeout");
        data.append("sessionNames", sessionStorage.getItem("sessionNames"));
        data.append("sessionIdNo",  sessionStorage.getItem("sessionIdNo"));
        execute(data,biller)
            .then(() => {

                sessionStorage.removeItem("isLoggedIn");
                router.push("/");

            })
            .catch(() => {
                // alert(e.message);
            });



    }, 20 * 60 * 1000); // 5 minutes in milliseconds
}

// Attach an event listener for user activity (e.g., mousemove, keypress, etc.)
document.addEventListener("mousemove", handleUserActivity);
document.addEventListener("keypress", handleUserActivity);

// Remember to remove event listeners when they are no longer needed (e.g., when user logs out)
// document.removeEventListener("mousemove", handleUserActivity);
// document.removeEventListener("keypress", handleUserActivity);

export default router;



